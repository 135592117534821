<div class="comparison-form" *ngIf="quotationList.length">
	<mat-stepper
		linear
		labelPosition="bottom"
		#stepper
		(selectionChange)="onStepChange($event)"
	>
		<mat-step [stepControl]="quotationFrmGroup" state="edit">
			<form [formGroup]="quotationFrmGroup">
				<ng-template matStepLabel>
					<div>1.</div>
					{{ "comparison.step1" | translate }}
				</ng-template>

				<div fxLayoutGap="8px grid" class="mobile-details-btn-list">
					<div>
						<button mat-button (click)="carDetails()">
							<img src="assets/image/car-icon.png" alt="" />
							{{ "common.carDetails" | translate }}
						</button>
					</div>

					<div>
						<button mat-button (click)="carPlan()">
							<img src="assets/image/shield-icon.png" alt="" />
							{{ "common.carProtectionPlan" | translate }}
						</button>
					</div>

					<div>
						<button mat-button (click)="editQuotation()">
							<img src="assets/image/edit.png" alt="" />
							{{ "common.quotation" | translate }}
						</button>
					</div>
				</div>

				<mat-card class="border-card">
					<div>
						<div fxFlex.lt-md="100" fxFlex>
							<div class="title-f">
								<img src="assets/image/car-icon.png" alt="" />
								{{ "common.carDetails" | translate }}
							</div>
							<div class="content-wrapper">
								<div class="left">
									<div class="label-desc">
										{{ "common.carNumber" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.vehicleNo || "-" }}
									</div>
								</div>
								<div class="left">
									<div class="label-desc">
										{{ "common.carModel" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.make || "-" }}
									</div>
								</div>

								<div class="left">
									<div class="label-desc">
										{{ "common.manufacturingYear" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.year || "-" }}
									</div>
								</div>

								<div class="left">
									<div class="label-desc">{{ "common.cc" | translate }} :</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.engineCC || "-" }}
									</div>
								</div>
								<div class="left">
									<div class="label-desc">
										{{ "common.seats" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.seat || "-" }}
									</div>
								</div>
								<div class="left">
									<div class="label-desc">
										{{ "common.engineNo" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.engineNo || "-" }}
									</div>
								</div>
								<div class="left">
									<div class="label-desc">
										{{ "common.chassisNo" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.chassisNo || "-" }}
									</div>
								</div>
							</div>
						</div>

						<div fxFlex.lt-md="100" fxFlex>
							<div class="title-f">
								<img src="assets/image/shield-icon.png" alt="" />
								{{ "common.carProtectionPlan" | translate }}
							</div>
							<div class="content-wrapper">
								<div class="left">
									<div class="label-desc">
										{{ "common.typeOfInsurance" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ "common." + requestData.insuranceType | translate }}
									</div>
								</div>
								<div class="left">
									<div class="label-desc">
										{{ "common.effectivePeriod" | translate }} :
									</div>
								</div>
								<div class="right">
									<div class="label-value">
										{{ requestData.effectiveDate | date : "dd MMM yyyy" }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<button mat-flat-button class="full-btn" (click)="editQuotation()">
						{{ "common.editQuotation" | translate }}
					</button>
				</mat-card>

				<mat-card
					class="quotation-validity"
					fxLayout="row"
					fxLayoutAlign="center center"
				>
					<img src="assets/icons/calendar-check.png" alt="" />
					<div class="smTitle-f color-purple ml-sm">
						{{ "comparison.quotationValidity" | translate }}
						{{ requestData?.expiredOn | date : "dd MMM yyyy" || "-" }}
					</div>
				</mat-card>

				<div
					class="insurer-list"
					*ngFor="let item of quotationList; let first = first"
					[ngClass]="
						first ? 'partner-insurer-list cheapest' : 'partner-insurer-list'
					"
				>
					<img
						*ngIf="first"
						class="banner"
						src="assets/image/cheapest-banner.png"
						alt=""
					/>
					<div class="insurer-content">
						<div class="insurer-logo">
							<img [src]="item.image" />
						</div>
						<div class="insurer-details">
							<div fxLayout="row">
								<div fxFlex>
									<div class="label">
										{{ "common.sumInsured" | translate }} ({{
											"common." + item?.typeOfSum | translate
										}}):
									</div>
									<div class="value">
										RM {{ item.sumInsured | number : "1.2-2" }}
									</div>
								</div>
								<button
									mat-stroked-button
									class="benefit-btn"
									(click)="benefitDialog(item)"
								>
									{{ "comparison.viewBenefit" | translate }}
								</button>
							</div>

							<div fxLayout="row" class="mt-md">
								<app-insurer-details
									[code]="item.code"
									[first]="first"
									[insuranceType]="requestData?.insuranceType"
									class="full-width"
									*ngIf="
										requestData?.insuranceType !== 'TPFT' ||
										item.code === 'Zurich'
									"
								>
								</app-insurer-details>
							</div>
						</div>
						<div class="insurer-select">
							<div class="insurer-amount">
								<span>RM</span>{{ item.total | number : "1.2-2" }}
							</div>
							<div class="insurer-instalment">
								{{ "common.pay" | translate }}
								<b>RM{{ item.total / 3 | number : "1.2-2" }}</b>
								{{ "common.everyMonth" | translate }} <br />{{
									"common.duration" | translate
								}}!
							</div>
							<!-- <button
								mat-flat-button
								class="buy-btn"
								(click)="selectQuotation(item.id)"							>
								{{ "comparison.buyNow" | translate }}
							</button> -->
							<button
								mat-flat-button
								class="buy-btn"
								(click)="openCarVariantDialog(item.id)"
							>
								{{ "comparison.buyNow" | translate }}
							</button>
						</div>
					</div>
					<!-- <div
						[ngClass]="first ? 'order-comparison cheapest' : 'order-comparison'"
						(click)="printComparison(item.id)"
					>
						{{ "comparison.viewOrderSummary" | translate }}
					</div> -->
				</div>

				<div
					class="mobile-insurer-list"
					[ngClass]="first ? 'cheapest' : ''"
					*ngFor="let item of quotationList; let first = first"
				>
					<img
						*ngIf="first"
						class="banner"
						src="assets/image/cheapest-banner.png"
						alt=""
					/>
					<div class="insurer-content">
						<div fxLayout="row" fxLayoutAlign=" center">
							<div class="insurer-logo">
								<img [src]="item.image" />
							</div>

							<div class="insurer-select">
								<div class="insurer-amount">
									<span>RM</span>{{ item.total | number : "1.2-2" }}
								</div>
								<div class="insurer-instalment">
									{{ "common.pay" | translate }}
									<b>RM{{ item.total / 3 | number : "1.2-2" }}</b>
									{{ "common.everyMonth" | translate }} <br />{{
										"common.duration" | translate
									}}!
								</div>
							</div>
						</div>

						<div class="insurer-value">
							<div fxFlex="50">
								<div class="label">
									{{ "common.sumInsured" | translate }} ({{ item?.typeOfSum }}):
								</div>
								<div class="value">
									RM {{ item.sumInsured | number : "1.2-2" }}
								</div>
							</div>
							<div fxFlex="50">
								<!-- <button
									mat-flat-button
									class="buy-btn"
									(click)="selectQuotation(item.id)"
								>
									{{ "comparison.buyNow" | translate }}
								</button> -->
								<button
									mat-flat-button
									class="buy-btn"
									(click)="openCarVariantDialog(item.id)"
								>
									{{ "comparison.buyNow" | translate }}
								</button>
							</div>
						</div>

						<div class="insurer-details">
							<div fxLayout="row" fxLayoutAlign=" center">
								<app-insurer-details
									[code]="item.code"
									[first]="first"
									[insuranceType]="requestData?.insuranceType"
									fxFlex
									*ngIf="
										requestData?.insuranceType !== 'TPFT' ||
										item.code === 'Zurich'
									"
								></app-insurer-details>
								<div>
									<button
										mat-stroked-button
										class="benefit-btn"
										(click)="benefitDialog(item)"
									>
										{{ "comparison.viewBenefit" | translate }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</mat-step>

		<mat-step
			[state]="addOnFrmGroup.valid ? 'edit' : 'close'"
			[stepControl]="addOnFrmGroup"
		>
			<ng-template matStepLabel>
				<div>2.</div>
				{{ "comparison.step2" | translate }}
			</ng-template>

			<button mat-button matStepperPrevious class="step-back-btn">
				<mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
			</button>

			<div fxLayout="row wrap" fxLayoutGap="16px grid">
				<div fxFlex.lt-md="100" fxFlex.gt-sm="60">
					<div class="section-container">
						<div class="title-sec">
							<div class="title-f">{{ "common.carDetails" | translate }}</div>
							<div class="divider"></div>
						</div>
						<div class="content-wrapper">
							<div class="left">
								<div class="label-desc">
									{{ "common.carNumber" | translate }} :
								</div>
							</div>
							<div class="right">
								<div class="label-value">{{ requestData.vehicleNo }}</div>
							</div>
							<div class="left">
								<div class="label-desc">
									{{ "common.carModel" | translate }} :
								</div>
							</div>
							<div class="right">
								<div class="label-value">{{ requestData.vehicle }}</div>
							</div>
						</div>
					</div>

					<div class="section-container">
						<div class="title-sec">
							<div class="title-f">
								{{ "common.carProtectionPlan" | translate }}
							</div>
							<div class="divider"></div>
						</div>

						<div class="content-wrapper">
							<div class="left">
								<div class="label-desc">
									{{ "common.typeOfInsurance" | translate }} :
								</div>
							</div>
							<div class="right">
								<div class="label-value">
									{{ "common." + requestData.insuranceType | translate }}
								</div>
							</div>
							<div class="left">
								<div class="label-desc">
									{{ "common.insuranceProvider" | translate }} :
								</div>
							</div>
							<div class="right">
								<div class="label-value">{{ selectedQuotation?.name }}</div>
							</div>
							<div class="left">
								<div class="label-desc">
									{{ "common.sumInsured" | translate }} ({{
										"common." + selectedQuotation?.typeOfSum | translate
									}}) :
								</div>
							</div>
							<div class="right">
								<div class="label-value">
									RM {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
								</div>
							</div>
							<div class="left">
								<div class="label-desc">
									{{ "common.effectivePeriod" | translate }} :
								</div>
							</div>
							<div class="right">
								<div class="label-value">
									{{ requestData.effectiveDate | date : "dd MMM yyyy" }}
								</div>
							</div>
						</div>
					</div>

					<button mat-flat-button class="full-btn" (click)="editQuotation()">
						{{ "common.editQuotation" | translate }}
					</button>

					<div class="section-container mt-sm">
						<div class="title-sec">
							<div class="title-f">{{ "addOn.addOns" | translate }}</div>
							<div class="divider"></div>
							<div class="desc">{{ "addOn.addOnsDesc" | translate }}</div>
						</div>
					</div>

					<!-- include add on -->
					<ng-container *ngFor="let item of includeAddOnList">
						<ng-container *ngIf="item.code !== 'PersonalAccident'">
							<mat-card class="addon-card included selected">
								<div class="included-banner">
									{{ "addOn.included" | translate }}
									<mat-icon>check_circle </mat-icon>
								</div>
								<div *ngIf="!requestData.isCompany">
									<div class="title">
										{{ "addOn." + item.code | translate }}
									</div>
									<div class="desc">
										{{ "addOn." + item.code + "Desc" | translate }}
									</div>
								</div>
								<div *ngIf="requestData.isCompany">
									<div fxLayout="row">
										<div fxFlex class="title">
											{{ "addOn." + item.code | translate }}
										</div>
										<div class="value selected">
											<span>RM</span> {{ getPrice(item) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="companyDriverTooltip"
											#companyDriverTrigger="matMenuTrigger"
											(mouseenter)="companyDriverTrigger.openMenu()"
										>
											info</mat-icon
										>
										<mat-menu
											#companyDriverTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="companyDriverTrigger.closeMenu()">
												{{ "addOn.UnlimitedTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="desc">
										<b>{{ "addOn.companyDriverCover" | translate }}</b>
										{{ "addOn.companyDriverCover2" | translate }}
										<b>RM {{ item.evaluate }}</b
										>.
									</div>
								</div>
							</mat-card>
						</ng-container>
					</ng-container>

					<ng-container *ngTemplateOutlet="addOnFrom"> </ng-container>

					<ng-container
						*ngIf="
							requestData?.businessType ===
								businessTypeOption.transferOwnership &&
							partnerCode === 'carsomeCapital'
						"
					>
						<div class="section-container mt-sm">
							<div class="title-sec">
								<div class="title-f">
									{{ "addOn.uniqueBenefit" | translate }}
								</div>
								<div class="divider"></div>
								<div class="desc">
									{{ "addOn.uniqueBenefitDesc" | translate }}
								</div>
							</div>
						</div>

						<img
							src="assets/image/unique-benefit.png"
							alt="unique-benefit"
							draggable="false"
							class="unique-benefit-graphic"
						/>
					</ng-container>

					<div class="comparison-actions">
						<button mat-button matStepperPrevious class="back-btn">
							{{ "common.back" | translate }}
						</button>
						<button
							mat-button
							matStepperNext
							class="proceed-btn"
							[disabled]="step2DisableBtnCondition()"
						>
							{{ "common.proceed" | translate }}
						</button>
					</div>
				</div>

				<div fxFlex.lt-md="100" fxFlex.gt-sm="40">
					<mat-expansion-panel
						class="summary-card"
						hideToggle
						disabled
						#addon="matExpansionPanel"
						[expanded]="screenWidth > 960"
					>
						<mat-expansion-panel-header
							[collapsedHeight]="'100%'"
							[expandedHeight]="'100%'"
						>
							<button
								mat-icon-button
								class="expand-btn"
								(click)="addon.expanded = !addon.expanded"
								*ngIf="!addon.expanded"
							>
								<mat-icon>keyboard_arrow_up</mat-icon>
							</button>
							<button
								mat-flat-button
								class="proceed-btn"
								matStepperNext
								[disabled]="step2DisableBtnCondition()"
							>
								<ng-container *ngTemplateOutlet="mobileNextBtn"> </ng-container>
							</button>
						</mat-expansion-panel-header>
						<button
							mat-icon-button
							class="expand-btn"
							(click)="addon.expanded = !addon.expanded"
							*ngIf="addon.expanded"
						>
							<mat-icon>keyboard_arrow_down</mat-icon>
						</button>
						<div class="expanded-content">
							<ng-container *ngTemplateOutlet="priceList"> </ng-container>
						</div>
					</mat-expansion-panel>
					<ng-container *ngTemplateOutlet="printOrderSummary"></ng-container>

					<div
						class="expand-blackdrop"
						(click)="addon.expanded = !addon.expanded"
						*ngIf="addon.expanded"
					></div>
				</div>
			</div>
		</mat-step>

		<mat-step
			[state]="infoFrmGroup.valid ? 'edit' : 'close'"
			[stepControl]="infoFrmGroup"
		>
			<ng-template matStepLabel>
				<div>3.</div>
				{{ "comparison.step3" | translate }}
			</ng-template>
			<button mat-button matStepperPrevious class="step-back-btn">
				<mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
			</button>

			<div fxLayout="row wrap" fxLayoutGap="16px grid">
				<div fxFlex.lt-md="100" fxFlex.gt-sm="60">
					<ng-template *ngTemplateOutlet="personalInfoFrom"></ng-template>
					<div class="comparison-actions pt-md">
						<button mat-button matStepperPrevious class="back-btn">
							{{ "common.back" | translate }}
						</button>
						<button
							mat-button
							matStepperNext
							class="proceed-btn"
							[disabled]="getUserDetailsBtnCondition()"
						>
							{{ "common.proceed" | translate }}
						</button>
					</div>
				</div>
				<div fxFlex.lt-md="100" fxFlex.gt-sm="40">
					<mat-expansion-panel
						class="summary-card"
						hideToggle
						disabled
						#personal="matExpansionPanel"
						[expanded]="screenWidth > 960"
					>
						<mat-expansion-panel-header
							[collapsedHeight]="'100%'"
							[expandedHeight]="'100%'"
						>
							<button
								mat-icon-button
								class="expand-btn"
								(click)="personal.expanded = !personal.expanded"
								*ngIf="!personal.expanded"
							>
								<mat-icon>keyboard_arrow_up</mat-icon>
							</button>
							<button
								mat-flat-button
								class="proceed-btn"
								matStepperNext
								[disabled]="getUserDetailsBtnCondition()"
							>
								<ng-container *ngTemplateOutlet="mobileNextBtn"> </ng-container>
							</button>
						</mat-expansion-panel-header>
						<button
							mat-icon-button
							class="expand-btn"
							(click)="personal.expanded = !personal.expanded"
							*ngIf="personal.expanded"
						>
							<mat-icon>keyboard_arrow_down</mat-icon>
						</button>
						<div class="expanded-content">
							<ng-container *ngTemplateOutlet="priceList"> </ng-container>
						</div>
					</mat-expansion-panel>
					<ng-container *ngTemplateOutlet="printOrderSummary"></ng-container>

					<div
						class="expand-blackdrop"
						(click)="personal.expanded = !personal.expanded"
						*ngIf="personal.expanded"
					></div>
				</div>
			</div>
		</mat-step>

		<mat-step
			[state]="agreementFrmGroup.valid ? 'edit' : 'close'"
			[stepControl]="agreementFrmGroup"
		>
			<form [formGroup]="agreementFrmGroup">
				<ng-template matStepLabel>
					<div>4.</div>
					{{ "comparison.step4" | translate }}
				</ng-template>
				<button mat-button matStepperPrevious class="step-back-btn">
					<mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
				</button>
				<div fxLayout="row wrap" fxLayoutGap="16px grid">
					<div fxFlex.lt-md="100" fxFlex.gt-sm="60">
						<div class="section-container mb-lg">
							<div class="title-sec">
								<div class="title-f">
									{{ "common.orderSummary" | translate }}
								</div>
								<div class="desc">
									{{ "common.orderSummaryDesc" | translate }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.carProtectionPlan" | translate }}
								</div>
								<button
									mat-stroked-button
									class="edit-summary-btn"
									(click)="stepper.selectedIndex = 2"
								>
									{{ "common.edit" | translate }}
								</button>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div fxLayout="row" fxLayoutGap="16px grid">
								<div fxFlex="50">
									<mat-card>
										<img
											class="full-width"
											[src]="selectedQuotation?.image"
											alt=""
										/>
									</mat-card>
								</div>
								<div class="content-wrapper m-t-0" fxFlex="50">
									<div>
										<div class="label-desc">
											{{ "common.insuranceProvider" | translate }} :
										</div>
										<div class="label-value">
											{{ selectedQuotation?.name }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.typeOfInsurance" | translate }} :
										</div>
										<div class="label-value">
											{{ "common." + requestData?.insuranceType | translate }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.sumInsured" | translate }} ({{
												"common." + selectedQuotation?.typeOfSum | translate
											}}) :
										</div>
										<div class="label-value">
											RM {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.effectivePeriod" | translate }} :
										</div>
										<div class="label-value">
											{{ requestData.effectiveDate | date : "dd MMM yyyy" }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="mb-sm"
							*ngIf="
								addOnFrmGroup.value?.isIncludeRoadtax || selectedAddOn.length
							"
						>
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">{{ "addOn.addOns" | translate }}</div>
								<button
									mat-stroked-button
									class="edit-summary-btn"
									(click)="stepper.selectedIndex = 1"
								>
									{{ "common.edit" | translate }}
								</button>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="add-ons-container">
								<div
									class="add-ons-cover"
									*ngIf="addOnFrmGroup.value?.isIncludeRoadtax"
								>
									{{ "addOn.roadTax" | translate }}
								</div>
								<div class="add-ons-cover" *ngFor="let item of selectedAddOn">
									{{ "addOn." + item.code | translate }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.carDetails" | translate }} :
								</div>
								<button
									mat-stroked-button
									class="edit-summary-btn"
									(click)="stepper.selectedIndex = 2"
								>
									{{ "common.edit" | translate }}
								</button>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="left label-desc">
									{{ "common.carNumber" | translate }} :
								</div>
								<div class="right label-value">
									{{ requestData?.vehicleNo }}
								</div>
								<div class="left label-desc">
									{{ "common.carModel" | translate }} :
								</div>
								<div class="right label-value">
									{{ requestData?.vehicle }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.personalDetails" | translate }}
								</div>
								<button
									mat-stroked-button
									class="edit-summary-btn"
									(click)="stepper.selectedIndex = 2"
								>
									{{ "common.edit" | translate }}
								</button>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ getNameLabel() | translate }}:
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.name || "-" }}
								</div>

								<ng-container *ngTemplateOutlet="personalDetail">
								</ng-container>

								<div class="label-desc left">
									{{ "common.occupation" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.occupation }}
								</div>

								<div class="label-desc left">
									{{ "common.mobileNo" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.mobileNo }}
								</div>

								<div class="label-desc left">
									{{ "common.email" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.email }}
								</div>
							</div>
						</div>

						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.residentialAddress" | translate }}
								</div>
								<button
									mat-stroked-button
									class="edit-summary-btn"
									(click)="stepper.selectedIndex = 2"
								>
									{{ "common.edit" | translate }}
								</button>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ "common.addressLineOne" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.address }}
								</div>
								<div class="label-desc left">
									{{ "common.addressLineTwo" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.address2 }}
								</div>
								<div class="label-desc left">
									{{ "common.city" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.city }}
								</div>
								<div class="label-desc left">
									{{ "common.postcode" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.getRawValue().postcode }}
								</div>
								<div class="label-desc left">
									{{ "common.state" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.state }}
								</div>
							</div>
						</div>

						<div class="mb-sm" *ngIf="addOnFrmGroup.value?.isIncludeRoadtax">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.deliveryAddress" | translate }}
								</div>
								<button
									mat-stroked-button
									class="edit-summary-btn"
									(click)="stepper.selectedIndex = 2"
								>
									{{ "common.edit" | translate }}
								</button>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ "common.addressLineOne" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.deliveryAddress }}
								</div>
								<div class="label-desc left">
									{{ "common.addressLineTwo" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.deliveryAddress2 }}
								</div>
								<div class="label-desc left">
									{{ "common.city" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.deliveryCity }}
								</div>
								<div class="label-desc left">
									{{ "common.postcode" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.deliveryPostcode }}
								</div>
								<div class="label-desc left">
									{{ "common.state" | translate }} :
								</div>
								<div class="label-value right">
									{{ infoFrmGroup.value.deliveryState }}
								</div>
							</div>
						</div>

						<ng-container
							*ngIf="
								requestData?.businessType ===
								businessTypeOption.transferOwnership
							"
						>
							<div class="mb-sm">
								<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
									<div class="mdTitle-f">
										{{ "common.financingDetails" | translate }}
									</div>
									<button
										mat-stroked-button
										class="edit-summary-btn"
										(click)="stepper.selectedIndex = 2"
									>
										{{ "common.edit" | translate }}
									</button>
									<div class="summary-divider grey" fxFlex></div>
								</div>
								<div class="content-wrapper">
									<div class="label-desc left">
										{{ "common.financingType" | translate }}:
									</div>
									<div class="label-value right">
										{{
											infoFrmGroup.value.financingType === "BankLoan"
												? "Bank loan"
												: "Cash"
										}}
									</div>
									<div class="label-desc left">Bank:</div>
									<div class="label-value right">
										{{ infoFrmGroup.value.bank || "-" }}
									</div>
									<div class="label-desc left">
										{{ "common.effectiveDate" | translate }}:
									</div>
									<div class="label-value right">
										{{
											infoFrmGroup.value.effectiveDate | date : "dd MMM yyyy"
										}}
									</div>
									<div class="label-desc left">
										{{ "common.dealerCase" | translate }} :
									</div>
									<div class="label-value right">
										{{ infoFrmGroup.value.dealerCase === true ? "Yes" : "No" }}
									</div>
								</div>
							</div>
							<div class="mb-sm">
								<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
									<div class="mdTitle-f">{{ "common.upload" | translate }}</div>
									<button
										mat-stroked-button
										class="edit-summary-btn"
										(click)="stepper.selectedIndex = 2"
									>
										{{ "common.edit" | translate }}
									</button>
									<div class="summary-divider grey" fxFlex></div>
								</div>
								<div class="content-wrapper">
									<div class="label-desc left">
										{{ "common.uploadMykad" | translate }}:
									</div>
									<div class="label-value right">
										<div *ngFor="let item of mykadOrPassport">
											{{ item.name }}
										</div>
										<div *ngIf="!mykadOrPassport.length">-</div>
									</div>
									<div
										class="label-desc left"
										*ngIf="infoFrmGroup.value.financingType === 'BankLoan'"
									>
										{{ "common.uploadLOU" | translate }}:
									</div>
									<div
										class="label-desc left"
										*ngIf="infoFrmGroup.value.financingType === 'Cash'"
									>
										{{ "common.uploadVSO" | translate }}:
									</div>

									<div class="label-value right">
										<div *ngFor="let item of letterOfUndertaking">
											{{ item.name }}
										</div>
										<div *ngIf="!letterOfUndertaking.length">-</div>
									</div>
									<div class="label-desc left">
										{{ "common.uploadImage" | translate }}:
									</div>
									<div class="label-value right">
										<div *ngFor="let item of vehiclePhoto">
											{{ item.name }}
										</div>
										<div *ngIf="!vehiclePhoto.length">-</div>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container
							*ngIf="requestData?.businessType === businessTypeOption.renewal"
						>
							<div class="mb-sm">
								<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
									<div class="mdTitle-f">
										{{ "common.financingDetails" | translate }}
									</div>
									<button
										mat-stroked-button
										class="edit-summary-btn"
										(click)="stepper.selectedIndex = 2"
									>
										{{ "common.edit" | translate }}
									</button>
									<div class="summary-divider grey" fxFlex></div>
								</div>
								<div class="content-wrapper">
									<div class="label-desc left">
										{{ "common.dealerCase" | translate }} :
									</div>
									<div class="label-value right">
										{{ infoFrmGroup.value.dealerCase === true ? "Yes" : "No" }}
									</div>
								</div>
							</div>
						</ng-container>

						<div class="multi-checkbox-section">
							<section>
								<mat-checkbox formControlName="agreeInfo"
									>{{ "common.confirmationOne" | translate }}
								</mat-checkbox>
							</section>

							<section formArrayName="doc">
								<ng-container
									*ngFor="
										let item of getAgreeFormArray()?.controls;
										let i = index
									"
								>
									<ng-container [formGroupName]="i">
										<mat-checkbox formControlName="checked"
											>{{ "common.confirmationTwo" | translate
											}}<b> {{ selectedQuotation?.name }} </b
											><a
												[href]="getAgreeFormArray()?.controls[i].value?.url"
												target="blank"
												>{{ getAgreeFormArray()?.controls[i].value?.name }}</a
											>.
										</mat-checkbox>
									</ng-container>
								</ng-container>
							</section>
						</div>

						<div class="comparison-actions">
							<button mat-button matStepperPrevious class="back-btn">
								{{ "common.back" | translate }}
							</button>
							<button
								mat-button
								(click)="submit()"
								class="proceed-btn"
								[disabled]="agreementFrmGroup.invalid"
							>
								{{ "common.proceed" | translate }}
							</button>
						</div>
					</div>

					<div fxFlex.lt-md="100" fxFlex.gt-sm="40">
						<mat-expansion-panel
							class="summary-card"
							hideToggle
							disabled
							#summary="matExpansionPanel"
							[expanded]="screenWidth > 960"
						>
							<mat-expansion-panel-header
								[collapsedHeight]="'100%'"
								[expandedHeight]="'100%'"
							>
								<button
									mat-icon-button
									class="expand-btn"
									(click)="summary.expanded = !summary.expanded"
									*ngIf="!summary.expanded"
								>
									<mat-icon>keyboard_arrow_up</mat-icon>
								</button>
								<button
									mat-flat-button
									class="proceed-btn"
									(click)="submit()"
									matStepperNext
									[disabled]="agreementFrmGroup.invalid"
								>
									<ng-container *ngTemplateOutlet="mobileNextBtn">
									</ng-container>
								</button>
							</mat-expansion-panel-header>
							<button
								mat-icon-button
								class="expand-btn"
								(click)="summary.expanded = !summary.expanded"
								*ngIf="summary.expanded"
							>
								<mat-icon>keyboard_arrow_down</mat-icon>
							</button>
							<div class="expanded-content">
								<ng-container *ngTemplateOutlet="priceList"> </ng-container>
							</div>
						</mat-expansion-panel>
						<ng-container *ngTemplateOutlet="printOrderSummary"></ng-container>

						<div
							class="expand-blackdrop"
							(click)="summary.expanded = !summary.expanded"
							*ngIf="summary.expanded"
						></div>
					</div>
				</div>
			</form>
		</mat-step>

		<ng-template matStepperIcon="edit">
			<mat-icon>edit</mat-icon>
		</ng-template>

		<ng-template matStepperIcon="close">
			<mat-icon>close</mat-icon>
		</ng-template>
	</mat-stepper>
</div>

<ng-template #priceList>
	<img [src]="selectedQuotation?.image" class="selected-insurer" alt="" />
	<mat-expansion-panel class="summary-expand">
		<mat-expansion-panel-header
			[collapsedHeight]="'100%'"
			[expandedHeight]="'100%'"
		>
			<mat-panel-title>
				{{ "common.benefits" | translate }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="expand-content">
			<app-benefit
				[code]="selectedQuotation?.code"
				[insuranceType]="requestData?.insuranceType"
			></app-benefit>
		</div>
	</mat-expansion-panel>

	<div class="content-wrapper">
		<div class="left">
			<div class="label-desc">{{ "common.sumInsured" | translate }} (SI)</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
			</div>
		</div>
		<div class="left">
			<div class="label-desc">{{ "common.basePremium" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ selectedQuotation?.basePremium | number : "1.2-2" }}
			</div>
		</div>
		<div class="left">
			<div class="label-desc">
				{{ "common.noClaimDiscount" | translate }} <br />
				<b>({{ requestData?.ncd * 100 | number : "1.0-2" }}%)</b>
			</div>
		</div>
		<div class="right">
			<div class="label-value text-right color-red">
				- RM
				{{
					selectedQuotation?.basePremium - selectedQuotation?.totalBasePremium
						| number : "1.2-2"
				}}
			</div>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div class="content-wrapper">
		<div class="left">
			<div class="label-desc">{{ "common.totalBasePremium" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ selectedQuotation?.totalBasePremium | number : "1.2-2" }}
			</div>
		</div>

		<div *ngIf="hasAddOn()">
			<span class="cover-label">{{ "addOn.addOnsCapital" | translate }}</span>
		</div>

		<ng-container *ngFor="let item of selectedAddOn">
			<ng-container *ngIf="!item.hidePriceBreakdown">
				<div class="left">
					<div class="label-desc">{{ "addOn." + item.code | translate }}</div>
				</div>
				<div class="right">
					<ng-container [ngSwitch]="item.code">
						<ng-container *ngSwitchCase="'Windshield'">
							<div class="label-value text-right">
								RM
								{{
									getPrice(item, addOnFrmGroup.value.windshield)
										| number : "1.2-2"
								}}
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'AdditionalDrivers'">
							<div class="label-value text-right">
								RM
								{{
									getPrice(item, getFormArray()?.controls.length)
										| number : "1.2-2"
								}}
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'FGAP'">
							<div class="label-value text-right">
								RM
								{{ addOnFrmGroup.value.fgap | number : "1.2-2" }}
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'EWP'">
							<div class="label-value text-right">
								RM
								{{ getPrice(item, addOnFrmGroup.value.ewp) | number : "1.2-2" }}
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'LLTP'">
							<div
								class="label-value text-right"
								[title]="'addOn.' + item.code | translate"
							>
								RM
								{{ getPrice(item, this.numberOfLltpSeats) | number : "1.2-2" }}
							</div>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div class="label-value text-right">
								RM {{ getPrice(item) | number : "1.2-2" }}
							</div>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
	</div>
	<mat-divider></mat-divider>

	<mat-divider></mat-divider>

	<div class="content-wrapper">
		<div class="left">
			<div class="label-desc">{{ "common.totalGrossPremium" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ getGrossPremium() | number : "1.2-2" }}
			</div>
		</div>
		<div class="left">
			<div class="label-desc">
				{{ "common.serviceTax" | translate }}
			</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ getTax() | number : "1.2-2" }}
			</div>
		</div>

		<div class="left">
			<div class="label-desc">{{ "common.stampDuty" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ selectedQuotation?.stampDutyPrice | number : "1.2-2" }}
			</div>
		</div>
	</div>

	<mat-divider></mat-divider>

	<div class="content-wrapper">
		<div class="left">
			<div class="label-desc">{{ "common.totalNetPremium" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ getTotalNetPremium() | number : "1.2-2" }}
			</div>
		</div>
		<ng-container *ngIf="selectedQuotation?.platformDiscountRate !== 0">
			<div class="left">
				<div class="label-desc">{{ "common.platformOffer" | translate }}</div>
			</div>
			<div class="right">
				<div class="label-value text-right color-red">
					- RM {{ getPlatformDiscountRate() | number : "1.2-2" }}
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="addOnFrmGroup.value?.isIncludeRoadtax">
			<div class="left">
				<div class="label-desc">{{ "addOn.roadTax" | translate }}</div>
			</div>
			<div class="right">
				<div class="label-value text-right">
					RM {{ requestData.roadTaxPrice | number : "1.2-2" }}
				</div>
			</div>
			<div class="left">
				<div class="label-desc">{{ "addOn.roadTaxDelivery" | translate }}</div>
			</div>
			<div class="right">
				<div class="label-value text-right">
					RM {{ requestData.roadTaxDeliveryPrice | number : "1.2-2" }}
				</div>
			</div>
		</ng-container>
	</div>

	<mat-divider></mat-divider>

	<div class="content-wrapper">
		<div class="left">
			<div class="label-desc">{{ "common.totalCharges" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ getTotalCharge() | number : "1.2-2" }}
			</div>
		</div>

		<ng-container *ngIf="promo">
			<div class="left">
				<div class="label-desc">{{ promo.name }}</div>
			</div>
			<div class="right">
				<div class="label-value text-right color-red">
					- RM {{ getTotalPromo() | number : "1.2-2" }}
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="selectedQuotation?.serviceTaxRate !== 0">
			<div class="left">
				<div class="label-desc">{{ "common.serviceFee" | translate }}</div>
			</div>
			<div class="right">
				<div class="label-value text-right">
					RM {{ getServiceFee() | number : "1.2-2" }}
				</div>
			</div>
		</ng-container>
	</div>

	<mat-divider></mat-divider>

	<div class="content-wrapper">
		<div class="left">
			<div class="label-desc">{{ "common.totalAmount" | translate }}</div>
		</div>
		<div class="right">
			<div class="label-value text-right">
				RM {{ getTotal() | number : "1.2-2" }}
			</div>
		</div>
	</div>

	<mat-divider></mat-divider>

	<ng-container share>
		<ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
	</ng-container>
</ng-template>

<ng-template #personalDetail>
	<div class="label-desc left">{{ getIdentityNoLabel() | translate }} :</div>
	<div class="label-value right">
		{{ requestData?.identityNo }}
	</div>

	<ng-container *ngIf="!requestData?.isCompany">
		<ng-container *ngIf="requestData?.isMalaysian">
			<ng-container *ngIf="requestData?.policIdentityNo">
				<div class="label-desc left">
					{{ "common.policeOrArmyIcNo" | translate }} :
				</div>
				<div class="label-value right">
					{{ requestData?.policIdentityNo || "-" }}
				</div>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="!requestData?.isMalaysian">
			<div class="label-desc left">
				{{ "common.nationality" | translate }} :
			</div>
			<div class="label-value right">
				{{ requestData?.nationality }}
			</div>
		</ng-container>

		<div class="label-desc left">{{ "common.gender" | translate }} :</div>
		<div class="label-value right">
			{{ "common." + requestData.gender | translate }}
		</div>
		<div class="label-desc left">
			{{ "common.maritalStatus" | translate }} :
		</div>
		<div class="label-value right">
			{{ "common." + requestData.maritalStatus | translate }}
		</div>

		<div class="label-desc left">{{ "common.dob" | translate }} :</div>
		<div class="label-value right">
			{{ requestData?.dob | date : "dd MMM yyyy" }}
		</div>
	</ng-container>
</ng-template>

<!-- Print order summary -->
<ng-template #printOrderSummary>
	<div class="generate-order-summary" (click)="print()">
		Generate order summary
	</div>
</ng-template>
<iframe id="iframePrint" style="display: none">
	<div id="printOrderSummary">
		<div style="max-width: 900px; margin: 0px auto">
			<img
				src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/carsomeCapital-logo.png"
				alt="test"
				style="width: 150px; margin: 32px 0px 10px"
				*ngIf="partnerCode == 'carsomeCapital'"
			/>
			<h2>{{ "common.orderSummary" | translate }}</h2>
			<hr style="color: #c4d2e3 !important" />
		</div>
		<div
			fxLayout="row"
			fxLayoutAlign="space-between"
			style="max-width: 900px; margin: 0px auto"
		>
			<div>
				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					style="margin: 24px 0px 5px"
				>
					<div>{{ "common.name" | translate }} :</div>
					<div style="font-weight: bold; padding-left: 20px">
						{{ infoFrmGroup.controls["name"].value || "-" }}
					</div>
				</div>
				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					style="margin: 5px 0px"
				>
					<div>{{ "common.icNo" | translate }} :</div>
					<div style="font-weight: bold; padding-left: 20px">
						{{ requestData?.identityNo }}
					</div>
				</div>

				<ng-container *ngIf="!requestData?.isCompany">
					<ng-container *ngIf="requestData?.isMalaysian">
						<ng-container *ngIf="requestData?.policIdentityNo">
							<div
								fxLayout="row"
								fxLayoutAlign="space-between"
								style="margin: 5px 0px"
							>
								<div>{{ "common.policeOrArmyIcNo" | translate }} :</div>
								<div style="font-weight: bold; padding-left: 20px">
									{{ requestData?.policIdentityNo || "-" }}
								</div>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>

				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					style="margin: 5px 0px"
				>
					<div>{{ "common.carPlateNo" | translate }} :</div>
					<div style="font-weight: bold; padding-left: 20px">
						{{ requestData?.vehicleNo || "-" }}
					</div>
				</div>
			</div>
			<div style="margin-top: 12px">
				<div
					style="
						background: #fff;
						padding: 15px 35px;
						border-radius: 12px;
						box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.108856);
					"
				>
					<!-- picture here -->
					<img [src]="selectedQuotation?.image" alt="" style="width: 150px" />
				</div>
			</div>
		</div>

		<!-- Details / Body -->
		<div style="margin-top: 30px">
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>{{ "common.sumInsured" | translate }} (SI)</div>
				<div style="font-weight: bold">
					RM {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
				</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>{{ "common.basePremium" | translate }}</div>
				<div style="font-weight: bold">
					RM {{ selectedQuotation?.basePremium | number : "1.2-2" }}
				</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>
					{{ "common.noClaimDiscount" | translate }} ({{
						requestData?.ncd * 100 | number : "1.0-2"
					}}%)
				</div>
				<div style="font-weight: bold">
					- RM
					{{
						selectedQuotation?.basePremium - selectedQuotation?.totalBasePremium
							| number : "1.2-2"
					}}
				</div>
			</div>

			<hr />

			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>{{ "common.totalBasePremium" | translate }}</div>
				<div style="font-weight: bold">
					RM {{ selectedQuotation?.totalBasePremium | number : "1.2-2" }}
				</div>
			</div>

			<div>
				<div *ngFor="let item of selectedAddOn">
					<div
						*ngIf="!item.hidePriceBreakdown"
						fxLayout="row"
						fxLayoutAlign="space-between"
					>
						<div>{{ "addOn." + item.code | translate }}</div>
						<div style="font-weight: bold">
							<ng-container [ngSwitch]="item.code">
								<ng-container *ngSwitchCase="'Windshield'">
									<div class="label-value text-right">
										RM
										{{
											getPrice(item, addOnFrmGroup.value.windshield)
												| number : "1.2-2"
										}}
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'AdditionalDrivers'">
									<div class="label-value text-right">
										RM
										{{
											getPrice(item, getFormArray()?.controls.length)
												| number : "1.2-2"
										}}
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'FGAP'">
									<div class="label-value text-right">
										RM
										{{ addOnFrmGroup.value.fgap | number : "1.2-2" }}
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'EWP'">
									<div class="label-value text-right">
										RM
										{{
											getPrice(item, addOnFrmGroup.value.ewp) | number : "1.2-2"
										}}
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'LLTP'">
									<div
										class="label-value text-right"
										[title]="'addOn.' + item.code | translate"
									>
										RM
										{{
											getPrice(item, this.numberOfLltpSeats) | number : "1.2-2"
										}}
									</div>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<div class="label-value text-right">
										RM {{ getPrice(item) | number : "1.2-2" }}
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>

				<hr />

				<div fxLayout="row" fxLayoutAlign="space-between">
					<div>{{ "common.totalGrossPremium" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ getGrossPremium() | number : "1.2-2" }}
					</div>
				</div>
				<div fxLayout="row" fxLayoutAlign="space-between">
					<div>
						{{ "common.serviceTax" | translate }} ({{
							selectedQuotation?.taxRate * 100 | number : "1.0-2"
						}}%)
					</div>
					<div style="font-weight: bold">
						RM {{ getTax() | number : "1.2-2" }}
					</div>
				</div>
				<div fxLayout="row" fxLayoutAlign="space-between">
					<div>{{ "common.stampDuty" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ selectedQuotation?.stampDutyPrice | number : "1.2-2" }}
					</div>
				</div>

				<hr />

				<div fxLayout="row" fxLayoutAlign="space-between">
					<div>{{ "common.totalNetPremium" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ getTotalNetPremium() | number : "1.2-2" }}
					</div>
				</div>
				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					*ngIf="selectedQuotation?.platformDiscountRate !== 0"
				>
					<div>{{ "common.platformOffer" | translate }}</div>
					<div style="font-weight: bold; color: red">
						- RM {{ getPlatformDiscountRate() | number : "1.2-2" }}
					</div>
				</div>

				<hr />

				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					*ngIf="addOnFrmGroup.value?.isIncludeRoadtax"
				>
					<div>{{ "addOn.roadTax" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ requestData.roadTaxPrice | number : "1.2-2" }}
					</div>
				</div>
				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					*ngIf="addOnFrmGroup.value?.isIncludeRoadtax"
				>
					<div>{{ "addOn.roadTaxDelivery" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ requestData.roadTaxDeliveryPrice | number : "1.2-2" }}
					</div>
				</div>
				<div fxLayout="row" fxLayoutAlign="space-between">
					<div>{{ "common.totalCharges" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ getTotalCharge() | number : "1.2-2" }}
					</div>
				</div>
				<div *ngIf="promo" fxLayout="row" fxLayoutAlign="space-between">
					<div>{{ promo.name }}</div>
					<div style="font-weight: bold; color: red">
						- RM {{ getTotalPromo() | number : "1.2-2" }}
					</div>
				</div>
				<div
					fxLayout="row"
					fxLayoutAlign="space-between"
					*ngIf="selectedQuotation?.serviceTaxRate !== 0"
				>
					<div>{{ "common.serviceFee" | translate }}</div>
					<div style="font-weight: bold">
						RM {{ getServiceFee() | number : "1.2-2" }}
					</div>
				</div>

				<hr />

				<div fxLayout="row" fxLayoutAlign="space-between">
					<div>{{ "common.totalAmount" | translate }}</div>
					<div style="font-weight: bold; font-size: 20px">
						RM {{ getTotal() | number : "1.2-2" }}
					</div>
				</div>
			</div>
		</div>
	</div>
</iframe>

<ng-template #addOnFrom>
	<!-- include add on -->
	<form [formGroup]="addOnFrmGroup">
		<!-- Add-ons-->
		<!-- EWP Teaser -->
		<!-- <ng-container *ngIf="partnerCode == 'carsomeCapital'">
			<mat-card class="addon-card disabled">
				<mat-checkbox [disableRipple]="true" (click)="$event.preventDefault()">
					<div fxLayout="row wrap">
						<div class="title" fxFlex="70">
							{{ "addOn.EWP" | translate }}
						</div>
						<span fxFlex></span>

						<div class="coming-soon">Coming Soon!</div>
						<mat-icon
							class="tooltip-icon"
							[matMenuTriggerFor]="EWPTooltip"
							#EWPTrigger="matMenuTrigger"
							(mouseenter)="EWPTrigger.openMenu()"
						>
							info
						</mat-icon>
						<mat-menu
							#EWPTooltip="matMenu"
							[overlapTrigger]="false"
							class="tooltip-popover"
						>
							<span (mouseleave)="EWPTrigger.closeMenu()">
								{{ "addOn.EWPTooltip" | translate }}
							</span>
						</mat-menu>
					</div>
				</mat-checkbox>
			</mat-card>
		</ng-container> -->
		<!-- End EWP Teaser -->

		<!-- Road Tax -->
		<!-- <mat-card
			class="addon-card"
			*ngIf="!requestData.eHailingUsed"
			[class.selected]="addOnFrmGroup.value?.isIncludeRoadtax"
		>
			<mat-checkbox
				formControlName="isIncludeRoadtax"
				(change)="toggleRoadTax($event)"
				[checked]="isRoadTaxSelected(addOnFrmGroup.value?.isIncludeRoadtax)"
			>
				<div fxLayout="row">
					<div fxFlex class="title">
						{{ "addOn.roadTax" | translate }}
					</div>
					<div class="value" *ngIf="isIncludeDigitalRoadtax">
						<span>RM</span>
						{{ requestData.roadTaxPrice | number : "1.2-2" }}
					</div>
					<div class="value" *ngIf="!isIncludeDigitalRoadtax">
						<span>RM</span>
						{{ requestData.roadTaxPrice + 15.00 | number : "1.2-2" }}
					</div>
					<mat-icon
						class="tooltip-icon"
						[matMenuTriggerFor]="roadTaxTooltip"
						#roadTaxTrigger="matMenuTrigger"
						(mouseenter)="roadTaxTrigger.openMenu()"
					>
						info
					</mat-icon>
					<mat-menu
						#roadTaxTooltip="matMenu"
						[overlapTrigger]="false"
						class="tooltip-popover"
					>
						<span (mouseleave)="roadTaxTrigger.closeMenu()">
							{{ "addOn.roadTaxTooltip" | translate }}
						</span>
					</mat-menu>
				</div>
			</mat-checkbox>
			<mat-radio-group
				*ngIf="showRoadTaxList"
				(change)="radioDigitalRoadTaxChange($event)"
			>
				<mat-radio-button [value]="includeDigitalRoadTax" [checked]="true">
					<ng-container>
						<div fxLayout="row" fxLayoutAlign=" center">
							<div class="title" fxFlex>
								{{ 'addOn.digitalRoadTax' | translate }}
							</div>
							<div class="value sub-right">
								<del>RM 8.00</del> {{ "free" | translate }}
								<div class="sub-value">
									{{ "limitedTimeOnly" | translate }}
								</div>
							</div>
						</div>
					</ng-container>
				</mat-radio-button>
				<mat-radio-button [value]="!includeDigitalRoadTax">
					<ng-container>
						<div fxLayout="row" fxLayoutAlign=" center">
							<div class="title" fxFlex>
								{{ 'addOn.physicalRoadtax' | translate }}
							</div>
							<div class="value sub-right">
								RM 15.00
								<div class="sub-value">
									{{ "handlingFee" | translate }}
								</div>
							</div>
						</div>
					</ng-container>
				</mat-radio-button>
			</mat-radio-group>
			<ng-container *ngIf="addOnFrmGroup.value?.isIncludeRoadtax && showRoadTaxList">
				<div fxLayout="row">
					<mat-checkbox fxFlex color="warn" formControlName="isNotBlacklisted">
						{{ "addOn.isNotBlacklisted" | translate }}
					</mat-checkbox>
					<div class="desc sub-right">
						{{ "addOn.deliveryCharge" | translate }} <br />
						<b> RM {{ requestData.roadTaxDeliveryPrice | number: "1.2-2" }}</b>
					</div>
				</div>
			</ng-container>
		</mat-card> -->
		<!-- Road Tax   -->
		<ng-container *ngFor="let category of addOnOptionalCategory">
			<ng-template
				#addOn
				[ngTemplateOutlet]="addOn"
				let-addOnList="addOnList"
				[ngTemplateOutletContext]="{
					addOnList: getAddOnListByCategory(category)
				}"
			>
				<ng-container [ngSwitch]="category">
					<ng-container *ngSwitchCase="'Windshield'">
						<ng-template
							#Windshield
							[ngTemplateOutlet]="Windshield"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div fxFlex class="title">
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{
												getPrice(addOn, addOnFrmGroup.value.windshield)
													| number : "1.2-2"
											}}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="windshieldTooltip"
											#windshieldTrigger="matMenuTrigger"
											(mouseenter)="windshieldTrigger.openMenu()"
										>
											info</mat-icon
										>
										<mat-menu
											#windshieldTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="windshieldTrigger.closeMenu()">
												{{ "addOn.WindshiledTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
								</mat-checkbox>

								<ng-container *ngIf="isAddOnSelected(addOn)">
									<!-- Slider style windshield -->
									<div class="slider-desc">
										{{ "addOn.coverage" | translate }} :
									</div>
									<app-range-slider
										[maxError]="
											'addOn.maxWindscreenError'
												| translate
													: {
															amount:
																getMinMaxWindscreenPrice(addOn, 'max')
																| currency : 'RM' : 'symbol' : '1.0-0'
													  }
										"
										[max]="getMinMaxWindscreenPrice(addOn, 'max')"
										[minError]="
											'addOn.minWindscreenError'
												| translate
													: {
															amount:
																getMinMaxWindscreenPrice(addOn, 'min')
																| currency : 'RM' : 'symbol' : '1.0-0'
													  }
										"
										[min]="getMinMaxWindscreenPrice(addOn, 'min')"
										[step]="50"
										[variantSmall]="true"
										formControlName="windshield"
									></app-range-slider>
								</ng-container>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'Flood'">
						<mat-card
							class="addon-card"
							[class.selected]="hasSelectedCategory('Flood')"
						>
							<ng-container *ngIf="addOnList.length === 1">
								<ng-template
									#flood
									[ngTemplateOutlet]="flood"
									let-addOn="addOn"
									[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
								>
									<mat-checkbox
										[value]="addOn"
										(change)="toggleAddOn($event)"
										[checked]="isAddOnSelected(addOn)"
									>
										<div fxLayout="row">
											<div fxFlex class="title">
												{{ "addOn." + addOn?.code | translate }}
											</div>
											<div class="value">
												<span>RM</span>
												{{ getPrice(addOn) | number : "1.2-2" }}
											</div>
											<mat-icon
												class="tooltip-icon"
												[matMenuTriggerFor]="FloodTooltip"
												#FloodTrigger="matMenuTrigger"
												(mouseenter)="FloodTrigger.openMenu()"
											>
												info</mat-icon
											>
											<mat-menu
												#FloodTooltip="matMenu"
												[overlapTrigger]="false"
												class="tooltip-popover"
											>
												<span (mouseleave)="FloodTrigger.closeMenu()">
													{{ "addOn.FloodTooltip" | translate }}
												</span>
											</mat-menu>
										</div>

										<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
											{{ "addOn.coverage" | translate }}:
											<ul>
												<li>{{ "addOn.fullFloodDesc" | translate }}</li>
											</ul>
										</div>
									</mat-checkbox>
								</ng-template>
							</ng-container>

							<ng-container *ngIf="addOnList.length !== 1">
								<ng-template
									#flood
									[ngTemplateOutlet]="flood"
									let-addOn="addOn"
									[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
								>
									<mat-checkbox
										[(ngModel)]="isFloodChecked"
										[ngModelOptions]="{ standalone: true }"
										[value]="addOn"
										(change)="toggleAddOn($event)"
										[checked]="isAddOnSelected(addOn)"
									>
										<div fxLayout="row">
											<div fxFlex class="title">
												{{ "addOn.Flood" | translate }}
											</div>
											<mat-icon
												class="tooltip-icon"
												[matMenuTriggerFor]="FloodTooltip"
												#FloodTrigger="matMenuTrigger"
												(mouseenter)="FloodTrigger.openMenu()"
											>
												info
											</mat-icon>
											<mat-menu
												#FloodTooltip="matMenu"
												[overlapTrigger]="false"
												class="tooltip-popover"
											>
												<span (mouseleave)="FloodTrigger.closeMenu()">
													{{ "addOn.FloodTooltip" | translate }}
												</span>
											</mat-menu>
										</div>
									</mat-checkbox>
								</ng-template>
								<mat-radio-group
									*ngIf="isFloodChecked"
									(change)="radioChange($event.value)"
								>
									<mat-radio-button
										*ngFor="let item of addOnList; let first = first"
										[value]="item"
										[checked]="first"
									>
										<div fxLayout="row">
											<div class="title" fxFlex>
												{{ "addOn." + item.code | translate }}
											</div>
											<div class="value">
												<span>RM</span>
												{{ getPrice(item) | number : "1.2-2" }}
											</div>
										</div>
										<div class="coverage-desc" *ngIf="isAddOnSelected(item)">
											{{ "addOn.coverage" | translate }}:
											<ul>
												<li *ngIf="item.code === 'FullFloodPeril'">
													{{ "addOn.fullFloodDesc" | translate }}
												</li>
												<li *ngIf="item.code === 'LimitedFloodPeril'">
													{{ "addOn.limitedFloodDesc" | translate }}
												</li>
											</ul>
										</div>
									</mat-radio-button>
								</mat-radio-group>
							</ng-container>
						</mat-card>
					</ng-container>

					<ng-container *ngSwitchCase="'EHailing'">
						<ng-template
							#eHailing
							[ngTemplateOutlet]="eHailing"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="EHailingTooltip"
											#EHailingTrigger="matMenuTrigger"
											(mouseenter)="EHailingTrigger.openMenu()"
										>
											info</mat-icon
										>
										<mat-menu
											#EHailingTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="EHailingTrigger.closeMenu()">
												{{ "addOn.EHailingTooltip" | translate }}
											</span>
										</mat-menu>
									</div>

									<div
										class="coverage-desc"
										style="white-space: normal"
										*ngIf="isAddOnSelected(addOn)"
									>
										{{ "addOn.eHailingDesc" | translate }}
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'LLOPNA'">
						<ng-template
							#LLOPNA
							[ngTemplateOutlet]="LLOPNA"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row wrap">
										<div class="title" fxFlex="70">
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<span fxFlex></span>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="LLOPNATooltip"
											#LLOPNATrigger="matMenuTrigger"
											(mouseenter)="LLOPNATrigger.openMenu()"
										>
											info</mat-icon
										>
										<mat-menu
											#LLOPNATooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="LLOPNATrigger.closeMenu()">
												{{ "addOn.LLOPNATooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>{{ "addOn.LLDesc1" | translate }}</li>
											<li>{{ "addOn.LLDesc2" | translate }}</li>
										</ul>
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'SRCC'">
						<ng-template
							#SRCC
							[ngTemplateOutlet]="SRCC"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="SRCCTooltip"
											#SRCCTrigger="matMenuTrigger"
											(mouseenter)="SRCCTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#SRCCTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="SRCCTrigger.closeMenu()">
												{{ "addOn.SRCCTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>{{ "addOn.SRCCDesc" | translate }}</li>
										</ul>
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'LLTP'">
						<ng-template
							#LLTP
							[ngTemplateOutlet]="LLTP"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{
												getPrice(addOn, this.numberOfLltpSeats)
													| number : "1.2-2"
											}}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="LLTPTooltip"
											#LLTPTrigger="matMenuTrigger"
											(mouseenter)="LLTPTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#LLTPTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="LLTPTrigger.closeMenu()">
												{{ "addOn.LLTPTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<label class="seat-label">
											{{ numberOfLltpSeats }} {{ "common.seats" | translate }}
										</label>
										<ul>
											<li>{{ "addOn.LLDesc1" | translate }}</li>
											<li>{{ "addOn.LLDesc2" | translate }}</li>
										</ul>
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<!-- EWP HERE  ##new-->
					<ng-container *ngSwitchCase="'EWP'">
						<ng-template
							#EWP
							[ngTemplateOutlet]="EWP"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn.EWP" | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{
												getPrice(addOn, addOnFrmGroup.value.ewp)
													| number : "1.2-2"
											}}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="EWPTooltip"
											#EWPTrigger="matMenuTrigger"
											(mouseenter)="EWPTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#EWPTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="EWPTrigger.closeMenu()">
												{{ "addOn.EWPTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
								</mat-checkbox>
								<div class="coverage-desc fit" *ngIf="isAddOnSelected(addOn)">
									<mat-form-field appearance="outline" class="full-width pt-xs">
										<mat-label>{{ "common.mileage" | translate }}</mat-label>
										<input
											matInput
											formControlName="ewp"
											autocomplete="off"
											mask="separator.2"
											thousandSeparator=","
											separatorLimit="99999999999999"
										/>
										<mat-error
											style="display: block !important"
											[hidden]="!addOnFrmGroup.controls.ewp?.errors?.ewp"
										>
											{{ "error.invalidEWP" | translate }}
											0
											{{ "error.to" | translate }}
											{{ ewpMileageMax | number : "2." }} km</mat-error
										>
									</mat-form-field>
									<div class="ewpDocs-container">
										<ng-container
											[ngTemplateOutlet]="ewpDocs"
											[ngTemplateOutletContext]="{
												title: 'comparison.mileageImg' | translate,
												name: 'mileageImage'
											}"
										>
										</ng-container>
										<ng-container
											[ngTemplateOutlet]="ewpDocs"
											[ngTemplateOutletContext]="{
												title: 'comparison.vehRegCard' | translate,
												name: 'vehRegCard'
											}"
										>
										</ng-container>
										<ng-container
											[ngTemplateOutlet]="ewpDocs"
											[ngTemplateOutletContext]="{
												title: 'comparison.vehInsRep' | translate,
												name: 'vehInsReport'
											}"
										>
										</ng-container>
									</div>
								</div>
							</mat-card>
						</ng-template>
					</ng-container>
					<!-- END EWP HERE ##new -->

					<ng-container *ngSwitchCase="'FGAP'">
						<ng-template
							#FGAP
							[ngTemplateOutlet]="FGAP"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="FGAPTooltip"
											#FGAPTrigger="matMenuTrigger"
											(mouseenter)="FGAPTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#FGAPTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="FGAPTrigger.closeMenu()">
												{{ "addOn.FGAPTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
								</mat-checkbox>
								<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
									<section class="box-selection mv-sm">
										<mat-radio-group
											class="b2c-radio fgap-radio-btn"
											formControlName="fgap"
										>
											<mat-radio-button
												[value]="getPrice(addOn, '0')"
												(change)="fgapAddOnsChanged('0')"
												selected
												[checked]="true"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan 1</div>
														<div class="smDesc-f">
															(Maximum limit: RM 10,000)
														</div>
													</div>
													<div class="mdTitle-f">
														<span>RM </span>
														{{ getPrice(addOn, "0") | number : "1.2-2" || "-" }}
													</div>
												</div>
											</mat-radio-button>
											<mat-radio-button
												[value]="getPrice(addOn, '1')"
												(change)="fgapAddOnsChanged('1')"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan 2</div>
														<div class="smDesc-f">
															(Maximum limit: RM 20,000)
														</div>
													</div>

													<div class="mdTitle-f">
														<span>RM </span
														>{{
															getPrice(addOn, "1") | number : "1.2-2" || "-"
														}}
													</div>
												</div>
											</mat-radio-button>
											<mat-radio-button
												[value]="getPrice(addOn, '2')"
												(change)="fgapAddOnsChanged('2')"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan 3</div>
														<div class="smDesc-f">
															(Maximum limit: RM 30,000)
														</div>
													</div>
													<div class="mdTitle-f">
														<span>RM </span>
														{{ getPrice(addOn, "2") | number : "1.2-2" || "-" }}
													</div>
												</div>
											</mat-radio-button>
										</mat-radio-group>
									</section>
								</div>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'MERW'">
						<ng-template
							#MERW
							[ngTemplateOutlet]="MERW"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="MERWTooltip"
											#MERWTrigger="matMenuTrigger"
											(mouseenter)="MERWTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#MERWTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="MERWTrigger.closeMenu()">
												{{ "addOn.MERWTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
								</mat-checkbox>
								<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
									<section class="box-selection mv-sm">
										<mat-radio-group
											class="b2c-radio fgap-radio-btn"
											formControlName="merw"
										>
											<mat-radio-button
												[value]="getPrice(addOn)"
												(change)="fgapAddOnsChanged('0')"
												selected
												[checked]="true"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">
															{{ "addOn.MERWPlanA" | translate }}
														</div>
													</div>
													<div class="mdTitle-f">
														<span>RM </span>
														{{ getPrice(addOn) | number : "1.2-2" || "-" }}
													</div>
												</div>
											</mat-radio-button>
											<!-- For future Plan B -->
											<!-- <mat-radio-button
												[value]="getPrice(addOn, '1')"
												(change)="fgapAddOnsChanged('1')"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan B</div>
													</div>

													<div class="mdTitle-f">
														<span>RM </span
														>{{
															getPrice(addOn, "1") | number : "1.2-2" || "-"
														}}
													</div>
												</div>
											</mat-radio-button> -->
											<!-- For future Plan C -->
											<!-- <mat-radio-button
												[value]="getPrice(addOn, '2')"
												(change)="fgapAddOnsChanged('2')"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan C</div>
													</div>
													<div class="mdTitle-f">
														<span>RM </span>
														{{ getPrice(addOn, "2") | number : "1.2-2" || "-" }}
													</div>
												</div>
											</mat-radio-button> -->
										</mat-radio-group>
									</section>
								</div>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'UnlimitedTowing'">
						<ng-template
							#UnlimitedTowing
							[ngTemplateOutlet]="UnlimitedTowing"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<!-- <mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="UnlimitedTowingTooltip"
											#UnlimitedTowingTrigger="matMenuTrigger"
											(mouseenter)="UnlimitedTowingTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#UnlimitedTowingTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="UnlimitedTowingTrigger.closeMenu()">
										 {{ "addOn.UnlimitedTowingTooltip" | translate }}
										</mat-menu> -->
									</div>
									<!-- <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>-</li>
											<li>-</li>
										</ul>
									</div> -->
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'Drivers'">
						<mat-card
							class="addon-card"
							[class.selected]="hasSelectedCategory('Drivers')"
						>
							<ng-container *ngIf="addOnList.length === 1">
								<ng-template
									#drivers
									[ngTemplateOutlet]="drivers"
									let-addOn="addOn"
									[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
								>
									<ng-container>
										<mat-checkbox
											[value]="addOn"
											(change)="toggleAddOn($event)"
											[checked]="isAddOnSelected(addOn)"
										>
											<div fxLayout="row">
												<div class="title" fxFlex>
													{{ "addOn." + addOn?.code | translate }}
												</div>
												<div
													class="value"
													*ngIf="addOn?.code !== 'AdditionalDrivers'"
												>
													<span>RM</span>
													{{ getPrice(addOn) | number : "1.2-2" }}
												</div>
												<mat-icon
													class="tooltip-icon"
													[matMenuTriggerFor]="unlimitedTooltip"
													#unlimitedTrigger="matMenuTrigger"
													(mouseenter)="unlimitedTrigger.openMenu()"
												>
													info
												</mat-icon>
												<mat-menu
													#unlimitedTooltip="matMenu"
													[overlapTrigger]="false"
													class="tooltip-popover"
												>
													<span (mouseleave)="unlimitedTrigger.closeMenu()">
														{{ "addOn.UnlimitedTooltip" | translate }}
													</span>
												</mat-menu>
												<div
													class="value text-right"
													*ngIf="addOn?.code === 'AdditionalDrivers'"
												>
													{{ "addOn.free" | translate }}
													<div
														class="sub-value"
														[translate]="'addOn.additionalDriverDesc'"
														[translateParams]="{
															val:
																maxAdditionalDriver ??
																translate.instant('number.1')
														}"
													></div>
												</div>
											</div>
										</mat-checkbox>

										<ng-container> </ng-container>
									</ng-container>
								</ng-template>
							</ng-container>
							<ng-container *ngIf="addOnList.length !== 1">
								<mat-checkbox
									[(ngModel)]="isDriverChecked"
									[ngModelOptions]="{ standalone: true }"
									(change)="radioChange(addOnList[0], true)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn.AdditionalDrivers" | translate }}
										</div>
									</div>
								</mat-checkbox>

								<mat-radio-group
									*ngIf="isDriverChecked"
									(change)="radioChange($event.value)"
								>
									<mat-radio-button
										*ngFor="let item of addOnList; let first = first"
										[value]="item"
										[checked]="first"
									>
										<ng-container *ngIf="item.code !== 'AdditionalDrivers'">
											<div fxLayout="row" fxLayoutAlign=" center">
												<div class="title" fxFlex>
													{{ "addOn.UnlimitedDrivers" | translate }}
												</div>
												<div class="value sub-right">
													<span>RM</span>
													{{ getPrice(item) | number : "1.2-2" }}
												</div>
											</div>
										</ng-container>

										<ng-container *ngIf="item.code === 'AdditionalDrivers'">
											<div fxLayout="row">
												<div class="title" fxFlex>
													{{ "addOn.additionalDriver" | translate }}
												</div>
												<div
													class="value"
													*ngIf="
														getFormArray()?.controls.length > 1;
														else freeDriverText
													"
												>
													<span>RM</span>
													{{
														getPrice(item, getFormArray()?.controls.length)
															| number : "1.2-2"
													}}
												</div>
												<ng-template #freeDriverText>
													<div class="value text-right">
														{{ "addOn.free" | translate }}
														<div
															class="sub-value"
															[translate]="'addOn.additionalDriverDesc'"
															[translateParams]="{
																val:
																	maxAdditionalDriver ??
																	translate.instant('number.1')
															}"
														></div>
													</div>
												</ng-template>
												<mat-icon
													class="tooltip-icon"
													[matMenuTriggerFor]="AdditionalDriversTooltip"
													#AdditionalDriversTrigger="matMenuTrigger"
													(mouseenter)="AdditionalDriversTrigger.openMenu()"
												>
													info
												</mat-icon>
											</div>

											<mat-menu
												#AdditionalDriversTooltip="matMenu"
												[overlapTrigger]="false"
												class="tooltip-popover"
											>
												<span
													(mouseleave)="AdditionalDriversTrigger.closeMenu()"
												>
													{{ "addOn.AdditionalDriversTooltip" | translate }}
												</span>
											</mat-menu>
										</ng-container>
									</mat-radio-button>
								</mat-radio-group>
							</ng-container>
							<ng-container *ngIf="hasSelectedCode('AdditionalDrivers')">
								<div
									formArrayName="drivers"
									*ngFor="let item of getFormArray()?.controls; let i = index"
								>
									<br />
									<ng-container [formGroupName]="i">
										<div fxLayout="row" fxLayoutAlign=" center">
											<div class="driver-count">
												{{ "addOn.additionalDriver" | translate }} #{{ i + 1 }}
											</div>
											<div class="driver-divider" fxFlex></div>
											<button
												class="driver-delete"
												(click)="deleteDriver(i)"
												mat-icon-button
												*ngIf="i !== 0"
												type="button"
											>
												<mat-icon>delete_outline</mat-icon>
											</button>
										</div>

										<br />

										<div class="form-wrapper">
											<div>
												<mat-form-field appearance="outline">
													<mat-label
														>{{ "common.name" | translate }}
													</mat-label>
													<input
														matInput
														autocomplete="off"
														formControlName="name"
													/>
													<mat-error>
														{{ "common.name" | translate }}
														{{
															getFormArrayControlError(i, "name")
																| inputError
																| translate
														}}
													</mat-error>
												</mat-form-field>

												<div class="sub-form-wrapper">
													<mat-form-field
														class="form-dropdown"
														appearance="outline"
													>
														<mat-label
															>{{ "common.idType" | translate }}
														</mat-label>

														<mat-select formControlName="idType">
															<mat-option
																*ngFor="let item of idTypeList"
																[value]="item.codeValue"
															>
																{{ item.codeDesc }}
															</mat-option>
														</mat-select>
													</mat-form-field>

													<mat-form-field
														class="form-input"
														appearance="outline"
													>
														<mat-label
															>{{ "common.idNo" | translate }}
														</mat-label>
														<input
															matInput
															autocomplete="off"
															[mask]="
																item.get('idType').value === 'IC_NO'
																	? '000000000000'
																	: ''
															"
															formControlName="identityNo"
														/>
														<mat-error>
															{{ "common.idNo" | translate }}
															{{
																getFormArrayControlError(i, "identityNo")
																	| inputError
																	| translate
															}}
														</mat-error>
													</mat-form-field>
												</div>

												<mat-form-field appearance="outline">
													<mat-label>{{ "common.dob" | translate }} </mat-label>
													<input
														matInput
														readonly
														[matDatepicker]="picker"
														formControlName="dob"
														(click)="picker.open()"
														[max]="maxDob"
													/>
													<mat-datepicker-toggle
														matSuffix
														[for]="picker"
													></mat-datepicker-toggle>
													<mat-datepicker #picker></mat-datepicker>
													<mat-error>
														{{ "common.dob" | translate }}
														{{
															getFormArrayControlError(i, "dob")
																| inputError
																| translate
														}}
													</mat-error>
												</mat-form-field>
											</div>

											<div class="additional-radio-btn">
												<mat-label
													>{{ "common.gender" | translate }}
												</mat-label>
												<mat-radio-group
													aria-label="Select an option"
													formControlName="gender"
												>
													<mat-radio-button [value]="genderOption.male">
														{{ "common.M" | translate }}
													</mat-radio-button>
													<mat-radio-button [value]="genderOption.female">
														{{ "common.F" | translate }}
													</mat-radio-button>
												</mat-radio-group>
											</div>

											<div class="additional-radio-btn">
												<mat-label
													>{{ "common.maritalStatus" | translate }}
												</mat-label>
												<mat-radio-group
													aria-label="Select an option"
													formControlName="maritalStatus"
												>
													<mat-radio-button
														[value]="maritalStatusOption.single"
													>
														{{ "common.Single" | translate }}
													</mat-radio-button>
													<mat-radio-button
														[value]="maritalStatusOption.married"
													>
														{{ "common.Married" | translate }}
													</mat-radio-button>
												</mat-radio-group>
											</div>

											<mat-form-field appearance="outline">
												<mat-label>{{
													"common.relationship" | translate
												}}</mat-label>
												<mat-select
													formControlName="relationship"
													placeholder="Please select"
												>
													<mat-option
														*ngFor="let item of relationshipList"
														[value]="item.id"
													>
														{{ item.name | translate }}
													</mat-option>
												</mat-select>
												<mat-error>
													{{ "common.relationship" | translate }}
													{{
														getFormArrayControlError(i, "relationship")
															| inputError
															| translate
													}}
												</mat-error>
											</mat-form-field>
										</div>
									</ng-container>
								</div>
								<button
									*ngIf="
										!maxAdditionalDriver ||
										getFormArray()?.length < maxAdditionalDriver
									"
									(click)="addDriver()"
									type="button"
									mat-stroked-button
									class="add-driver-btn"
								>
									<mat-icon>add_circle</mat-icon>
									{{ "addOn.addDriver" | translate }}
								</button>
							</ng-container>
						</mat-card>
					</ng-container>

					<ng-container *ngSwitchCase="'VBET'">
						<mat-card
							class="addon-card"
							[class.selected]="hasSelectedCategory('VBET')"
						>
							<ng-container *ngIf="addOnList.length === 1">
								<ng-template
									#VBET
									[ngTemplateOutlet]="VBET"
									let-addOn="addOn"
									[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
								>
									<mat-checkbox
										[value]="addOn"
										(change)="toggleAddOn($event)"
										[checked]="isAddOnSelected(addOn)"
									>
										<div fxLayout="row">
											<div fxFlex class="title">
												{{ "addOn." + addOn?.code | translate }}
											</div>
											<div class="value">
												<span>RM</span>
												{{ getPrice(addOn) | number : "1.2-2" }}
											</div>
											<mat-icon
												class="tooltip-icon"
												[matMenuTriggerFor]="VBETTooltip"
												#VBETTrigger="matMenuTrigger"
												(mouseenter)="VBETTrigger.openMenu()"
											>
												info</mat-icon
											>
											<mat-menu
												#VBETTooltip="matMenu"
												[overlapTrigger]="false"
												class="tooltip-popover"
											>
												<span (mouseleave)="VBETTrigger.closeMenu()">
													{{ "addOn.VBETTooltip" | translate }}
												</span>
											</mat-menu>
										</div>
									</mat-checkbox>
								</ng-template>
							</ng-container>
						</mat-card>
					</ng-container>

					<ng-container *ngSwitchCase="'BISP'">
						<ng-template
							#BISP
							[ngTemplateOutlet]="BISP"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="BISPTooltip"
											#BISPTrigger="matMenuTrigger"
											(mouseenter)="BISPTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#BISPTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="BISPTrigger.closeMenu()">
												{{ "addOn.BISPTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>{{ "addOn.BISPDesc" | translate }}</li>
										</ul>
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'MPAPlus'">
						<ng-template
							#MPAPlus
							[ngTemplateOutlet]="MPAPlus"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="MPAPlusTooltip"
											#MPAPlusTrigger="matMenuTrigger"
											(mouseenter)="MPAPlusTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#MPAPlusTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="MPAPlusTrigger.closeMenu()">
												{{ "addOn.MPAPlusTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>{{ "addOn.MPAPlusDesc" | translate }}</li>
										</ul>
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'PA'">
						<ng-template
							#PAPlan
							[ngTemplateOutlet]="PAPlan"
							let-addOn="addOn"
							[ngTemplateOutletContext]="{ addOn: addOnList[0] }"
						>
							<mat-card
								class="addon-card"
								[class.selected]="isAddOnSelected(addOn)"
							>
								<mat-checkbox
									[value]="addOn"
									(change)="toggleAddOn($event)"
									[checked]="isAddOnSelected(addOn)"
								>
									<div fxLayout="row">
										<div class="title" fxFlex>
											{{ "addOn." + addOn?.code | translate }}
										</div>
										<div class="value">
											<span>RM</span>
											{{ getPrice(addOn) | number : "1.2-2" }}
										</div>
										<mat-icon
											class="tooltip-icon"
											[matMenuTriggerFor]="PAPlanTooltip"
											#PAPlanTrigger="matMenuTrigger"
											(mouseenter)="PAPlanTrigger.openMenu()"
										>
											info
										</mat-icon>
										<mat-menu
											#PAPlanTooltip="matMenu"
											[overlapTrigger]="false"
											class="tooltip-popover"
										>
											<span (mouseleave)="PAPlanTrigger.closeMenu()">
												{{ "addOn.PAPlanTooltip" | translate }}
											</span>
										</mat-menu>
									</div>
									<div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
										{{ "addOn.coverage" | translate }}:
										<ul>
											<li>{{ "addOn.PAPlanDesc" | translate }}</li>
										</ul>
									</div>
								</mat-checkbox>
							</mat-card>
						</ng-template>
					</ng-container>
				</ng-container>
			</ng-template>
		</ng-container>
		<!-- Add-ons end -->
	</form>
</ng-template>

<ng-template #personalInfoFrom>
	<form [formGroup]="infoFrmGroup">
		<div class="section-container">
			<div class="title-sec">
				<div class="title-f">{{ "common.personalDetails" | translate }}</div>
				<div class="divider"></div>
			</div>

			<div class="content-wrapper">
				<ng-container *ngTemplateOutlet="personalDetail"> </ng-container>
			</div>

			<div class="form-field mt-sm">
				<mat-form-field appearance="outline">
					<mat-label>{{ getNameLabel() | translate }}</mat-label>
					<input matInput autocomplete="name" formControlName="name" />
					<mat-error>
						{{ getNameLabel() | translate }}
						{{ infoFrmGroup.controls?.name?.errors | inputError | translate }}
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ "common.occupation" | translate }}</mat-label>
					<input matInput autocomplete="off" formControlName="occupation" />
					<mat-error>
						{{ "common.occupation" | translate }}
						{{
							infoFrmGroup.controls?.occupation?.errors | inputError | translate
						}}
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ "common.mobileNo" | translate }}</mat-label>
					<ngx-mat-intl-tel-input
						[enableSearch]="true"
						[preferredCountries]="['my']"
						formControlName="mobileNo"
					>
					</ngx-mat-intl-tel-input>
					<mat-error>
						{{ "common.mobileNo" | translate }}
						{{
							infoFrmGroup.controls?.mobileNo?.errors | inputError | translate
						}}
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ "common.email" | translate }}</mat-label>
					<input matInput formControlName="email" autocomplete="email" />
					<mat-error *ngIf="infoFrmGroup.get('email').errors?.email">
						{{ "error.invalidEmail" | translate }}</mat-error
					>
					<mat-error *ngIf="infoFrmGroup.get('email').errors?.required"
						>{{ "common.email" | translate }}
						{{
							infoFrmGroup.controls?.email?.errors | inputError | translate
						}}</mat-error
					>
				</mat-form-field>
			</div>
		</div>
		<div class="section-container">
			<div class="title-sec">
				<div class="title-f">{{ "common.residentialAddress" | translate }}</div>
				<div class="divider"></div>
			</div>
			<div class="form-field">
				<mat-form-field appearance="outline">
					<mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
					<input matInput formControlName="address" />
					<mat-error>
						{{ "common.addressLineOne" | translate }}
						{{
							infoFrmGroup.controls?.address?.errors | inputError | translate
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
					<input matInput formControlName="address2" />
					<mat-error>
						{{ "common.addressLineTwo" | translate }}
						{{
							infoFrmGroup.controls?.address2?.errors | inputError | translate
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>{{ "common.city" | translate }}</mat-label>
					<input matInput formControlName="city" />
					<mat-error>
						{{ "common.city" | translate }}
						{{ infoFrmGroup.controls?.city?.errors | inputError | translate }}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="left">
					<mat-label>{{ "common.postcode" | translate }}</mat-label>
					<input matInput formControlName="postcode" />
				</mat-form-field>
				<mat-form-field appearance="outline" class="right">
					<mat-label>{{ "common.state" | translate }}</mat-label>
					<mat-select formControlName="state">
						<mat-option *ngFor="let item of stateList" [value]="item">{{
							item
						}}</mat-option>
					</mat-select>
					<mat-error>
						{{ "common.state" | translate }}
						{{ infoFrmGroup.controls?.state?.errors | inputError | translate }}
					</mat-error>
				</mat-form-field>

				<section *ngIf="addOnFrmGroup.value?.isIncludeRoadtax">
					<mat-checkbox
						[checked]="isSameAddressChecked()"
						(change)="addressIsChecked($event)"
					>
						{{ "common.sameDeliveryAddress" | translate }}
					</mat-checkbox>
				</section>
			</div>
		</div>

		<div
			class="section-container"
			*ngIf="addOnFrmGroup.value?.isIncludeRoadtax && !isSameAddressCheck"
		>
			<div class="title-sec">
				<div class="title-f">{{ "common.deliveryAddress" | translate }}</div>
				<div class="divider"></div>
			</div>
			<div class="form-field">
				<mat-form-field appearance="outline">
					<mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
					<input matInput formControlName="deliveryAddress" />
					<mat-error>
						{{ "common.addressLineOne" | translate }}
						{{
							infoFrmGroup.controls?.deliveryAddress?.errors
								| inputError
								| translate
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
					<input matInput formControlName="deliveryAddress2" />
					<mat-error>
						{{ "common.addressLineTwo" | translate }}
						{{
							infoFrmGroup.controls?.deliveryAddress2?.errors
								| inputError
								| translate
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>{{ "common.city" | translate }}</mat-label>
					<input matInput formControlName="deliveryCity" />
					<mat-error>
						{{ "common.city" | translate }}
						{{
							infoFrmGroup.controls?.deliveryCity?.errors
								| inputError
								| translate
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="left">
					<mat-label>{{ "common.postcode" | translate }}</mat-label>
					<input matInput formControlName="deliveryPostcode" mask="00000" />
					<mat-error>
						{{ "common.postcode" | translate }}
						{{
							infoFrmGroup.controls?.deliveryPostcode?.errors
								| inputError
								| translate
						}}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="right">
					<mat-label>{{ "common.state" | translate }}</mat-label>
					<mat-select formControlName="deliveryState">
						<mat-option *ngFor="let item of stateList" [value]="item">{{
							item
						}}</mat-option>
					</mat-select>
					<mat-error>
						{{ "common.state" | translate }}
						{{
							infoFrmGroup.controls?.deliveryState?.errors
								| inputError
								| translate
						}}
					</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div
			class="section-container"
			*ngIf="requestData?.businessType === businessTypeOption.transferOwnership"
		>
			<div class="title-sec">
				<div class="title-f">{{ "common.financingDetails" | translate }}</div>
				<div class="divider"></div>
			</div>

			<section>
				<mat-radio-group
					class="b2c-radio mh-sm"
					aria-label="Financing type"
					matInput
					formControlName="financingType"
				>
					<mat-radio-button color="primary" value="BankLoan">
						{{ "common.bankLoan" | translate }}
					</mat-radio-button>
					<mat-radio-button color="primary" value="Cash">
						{{ "common.cash" | translate }}
					</mat-radio-button>
				</mat-radio-group>
			</section>

			<mat-form-field
				appearance="outline"
				class="full-width"
				*ngIf="infoFrmGroup.controls['financingType'].value === 'BankLoan'"
			>
				<mat-label>Bank</mat-label>
				<input
					type="text"
					[placeholder]="'comparison.startTyping' | translate"
					aria-label="Bank"
					matInput
					formControlName="bank"
					[matAutocomplete]="auto"
				/>

				<mat-autocomplete #auto="matAutocomplete">
					<mat-option
						*ngFor="let item of filteredHirePurchase | async"
						[value]="item"
					>
						{{ item }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field appearance="outline" class="input-field full-width">
				<mat-label> {{ "common.effectiveDate" | translate }}</mat-label>
				<input
					matInput
					(click)="picker.open()"
					[matDatepicker]="picker"
					formControlName="effectiveDate"
					readonly
				/>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div>
		<!-- <section>
			<mat-checkbox
				class="checkbox-align-top"
				(change)="dealerCaseChanged($event)"
				formControlName="dealerCase"
			>
				{{ "common.dealerCaseTxt" | translate }}
			</mat-checkbox>
		</section> -->

		<div
			class="section-container"
			*ngIf="requestData?.businessType === businessTypeOption.transferOwnership"
		>
			<div class="title-sec">
				<div class="title-f">{{ "common.upload" | translate }}</div>
				<div class="divider"></div>
			</div>
			<div class="upload-documents">
				<mat-card>
					<div fxLayout="row" fxLayoutAlign="space-between">
						<div class="mr-sm">
							<div fxFlex>
								<img
									src="assets/icons/id-icon.png"
									alt="id"
									class="desktop-icon"
								/>
							</div>
							<div style="max-width: 300px">
								<div class="mdTitle-f">
									{{ "common.uploadMykad" | translate }}
								</div>
								<div>
									{{ "common.uploadMykadDesc" | translate }}
								</div>
								<div *ngIf="mykadOrPassport" class="mt-sm">
									<div
										*ngFor="let item of mykadOrPassport; let i = index"
										fxLayout="row"
										fxLayoutAlign=" center"
									>
										<div class="underlined-blue">
											{{ item?.name }}
										</div>
										<div>
											<button
												mat-icon-button
												(click)="deleteFile('mykadOrPassport', i)"
											>
												<mat-icon>cancel</mat-icon>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<input
								type="file"
								accept="image/*, application/pdf"
								id="mykadOrPassport"
								multiple
								(change)="handleFileInput($event)"
								name="mykadOrPassport"
								style="display: none"
							/>
							<label for="mykadOrPassport" class="upload-label-btn">
								<mat-icon>add_circle</mat-icon>{{ "common.upload" | translate }}
							</label>
						</div>
					</div>
				</mat-card>
				<mat-card>
					<div fxLayout="row" fxLayoutAlign="space-between">
						<div class="mr-sm">
							<div fxFlex>
								<img
									src="assets/icons/lou-icon.png"
									alt="lou"
									class="desktop-icon"
								/>
							</div>
							<div style="max-width: 300px">
								<div
									class="mdTitle-f"
									*ngIf="infoFrmGroup.value.financingType === 'BankLoan'"
								>
									{{ "common.uploadLOU" | translate }}
								</div>
								<div
									class="mdTitle-f"
									*ngIf="infoFrmGroup.value.financingType === 'Cash'"
								>
									{{ "common.uploadVSO" | translate }}
								</div>
								<div>{{ "common.uploadLOUVSODesc" | translate }}</div>
								<div *ngIf="letterOfUndertaking" class="mt-sm">
									<div
										*ngFor="let item of letterOfUndertaking; let i = index"
										fxLayout="row"
										fxLayoutAlign=" center"
									>
										<div class="underlined-blue">
											{{ item?.name }}
										</div>
										<div>
											<button
												mat-icon-button
												(click)="deleteFile('letterOfUndertaking', i)"
											>
												<mat-icon>cancel</mat-icon>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<input
								type="file"
								accept="image/*, application/pdf"
								id="letterOfUndertaking"
								multiple
								(change)="handleFileInput($event)"
								name="letterOfUndertaking"
								style="display: none"
							/>
							<label for="letterOfUndertaking" class="upload-label-btn">
								<mat-icon class="">add_circle</mat-icon
								>{{ "common.upload" | translate }}
							</label>
						</div>
					</div>
				</mat-card>
				<mat-card>
					<div fxLayout="row" fxLayoutAlign="space-between">
						<div class="mr-sm">
							<div fxFlex>
								<img
									src="assets/image/car-icon.png"
									alt="car"
									class="desktop-icon"
								/>
							</div>
							<div style="max-width: 300px">
								<div class="mdTitle-f">
									{{ "common.uploadImage" | translate }}
								</div>
								<div>{{ "common.uploadImageDesc" | translate }}</div>
								<div *ngIf="vehiclePhoto" class="mt-sm">
									<div
										*ngFor="let item of vehiclePhoto; let i = index"
										fxLayout="row"
										fxLayoutAlign=" center"
									>
										<div class="underlined-blue">
											{{ item?.name }}
										</div>
										<div>
											<button
												mat-icon-button
												(click)="deleteFile('vehiclePhoto', i)"
											>
												<mat-icon>cancel</mat-icon>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<input
								type="file"
								accept="image/*, application/pdf"
								id="vehiclePhoto"
								multiple
								(change)="handleFileInput($event)"
								name="vehiclePhoto"
								style="display: none"
							/>
							<label for="vehiclePhoto" class="upload-label-btn">
								<mat-icon class="">add_circle</mat-icon
								>{{ "common.upload" | translate }}
							</label>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
		<div>
			<!-- Dealer Case ##new -->
			<div class="color-input pt-sm pb-xs">
				{{ "common.dealerCaseTxt" | translate }}:
			</div>
			<section class="box-selection">
				<mat-radio-group
					class="b2c-radio"
					aria-label="Select an option"
					(change)="dealerCaseChanged($event)"
					formControlName="dealerCase"
				>
					<mat-radio-button [value]="true"
						>{{ "renewal.yes" | translate }}
					</mat-radio-button>
					<mat-radio-button [value]="false"
						>{{ "renewal.no" | translate }}
					</mat-radio-button>
				</mat-radio-group>
			</section>
			<mat-form-field
				class="full-width"
				appearance="outline"
				*ngIf="
					partnerCode === 'carsomeCapital' && infoFrmGroup.value.dealerCase
				"
			>
				<mat-label>{{ "common.dealerOutlets" | translate }}</mat-label>
				<input
					type="text"
					[placeholder]="'comparison.startTyping' | translate"
					aria-label="Bank"
					matInput
					formControlName="dealerOutlets"
					[matAutocomplete]="dealerOutlets"
				/>

				<mat-autocomplete #dealerOutlets="matAutocomplete">
					<mat-option
						*ngFor="let item of filteredDealerOutletsList | async"
						[value]="item"
					>
						{{ item }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<ng-container
				*ngIf="
					infoFrmGroup.value.dealerCase &&
					infoFrmGroup.value.dealerOutlets === 'Others'
				"
			>
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>{{ "common.customDealerOutlet" | translate }}</mat-label>
					<input
						type="text"
						[placeholder]="'comparison.startTyping' | translate"
						matInput
						formControlName="customDealerOutlet"
					/>
				</mat-form-field>
			</ng-container>
			<!-- End Dealer Case ##new -->
		</div>
	</form>
</ng-template>

<ng-template #mobileNextBtn>
	<div class="title">
		{{ "common.proceed" | translate }}
	</div>
	<div>
		<div class="value">RM {{ getTotal() | number : "1.2-2" }}</div>
	</div>
</ng-template>

<ng-template #ewpDocs let-title="title" let-name="name">
	<div class="upload-documents">
		<mat-card>
			<div fxLayout="row" fxLayoutAlign="space-between center">
				<div class="smTitle-f pr-sm">{{ title }}</div>
				<input
					type="file"
					accept="image/*, application/pdf"
					[id]="name"
					(change)="handleFileInput($event, name)"
					name="ewpFiles"
					style="display: none"
				/>
				<button class="blue-circle" mat-icon-button type="button">
					<label [for]="name" class="cursor-pointer">
						<mat-icon class="upload-icon">upload</mat-icon>
					</label>
				</button>
			</div>
			<ng-container *ngIf="ewpFiles[name].length">
				<div fxLayout="row" fxLayoutAlign=" center" class="pt-xs">
					<div class="underlined-blue">
						{{ ewpFiles[name][0].name }}
					</div>
					<div>
						<button mat-icon-button (click)="deleteFile('ewpFiles', 0, name)">
							<mat-icon>cancel</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</mat-card>
	</div>
</ng-template>

<ng-template #promoTemplate>
	<div class="promo-input pt-sm" (click)="$event.stopPropagation()">
		<div class="flex">
			<mat-form-field appearance="outline">
				<input
					matInput
					[placeholder]="'common.enterPromoCode' | translate"
					[(ngModel)]="promoCode"
					[readonly]="promo != null"
					autocomplete="off"
					(click)="$event.stopPropagation()"
					[ngModelOptions]="{ standalone: true }"
				/>
				<div class="floating-btn">
					<button
						type="button"
						mat-icon-button
						matSuffix
						(click)="$event.stopPropagation(); clearPromoCode()"
						*ngIf="promoCode"
					>
						<mat-icon class="color-secondary">cancel</mat-icon>
					</button>

					<button
						type="button"
						mat-icon-button
						matSuffix
						*ngIf="promo && promoCode"
					>
						<mat-icon class="color-green">check_circle</mat-icon>
					</button>
				</div>
				<button
					type="button"
					mat-flat-button
					matSuffix
					(click)="$event.stopPropagation(); applyPromoCode()"
					*ngIf="!promo || !promoCode"
				>
					{{ "common.apply" | translate }}
				</button>

				<button
					type="button"
					mat-flat-button
					matSuffix
					(click)="$event.stopPropagation(); applyPromoCode()"
					*ngIf="promo && promoCode"
				>
					{{ "common.applied" | translate }}
				</button>
			</mat-form-field>
		</div>
		<div class="promo-error" *ngIf="promoError">
			{{ "common." + promoError | translate }}
		</div>
		<div class="promo-valid" *ngIf="promo && promoCode">
			Promo code applied.
		</div>
	</div>
</ng-template>
