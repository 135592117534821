import {
	StepperSelectionEvent,
	STEPPER_GLOBAL_OPTIONS
} from "@angular/cdk/stepper";
import { Component, HostListener, ViewChild, OnInit } from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { map, Observable, startWith } from "rxjs";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { TransferOwnershipDataService } from "src/app/share/data-service/transfer-ownership.data.service";
import { FileFunction } from "src/app/share/function/file.function";
import { ValidatorFunction } from "src/app/share/function/validator.function";
import {
	ProductAddOnInterface,
	QuotationInterface,
	MotorRequestInterface,
	PromoCodeInterface,
	ProductDocumentInterface
} from "src/app/share/interface/motor.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { PartnerService } from "src/app/share/service/partner.service";
import { AgentCodeDialogComponent } from "../agent-code-dialog/agent-code-dialog.component";
import { BenefitDialogComponent } from "../benefit-dialog/benefit-dialog.component";
import { CarDetailsDialogComponent } from "../car-details-dialog/car-details-dialog.component";
import { CarPlanDialogComponent } from "../car-plan-dialog/car-plan-dialog.component";
import { DealerCaseDialogComponent } from "../dealer-case-dialog/dealer-case-dialog.component";
import { EditQuotationsComponent } from "../edit-quotations/edit-quotations.component";
import { SubmitSuccessDialogComponent } from "../submit-success-dialog/submit-success-dialog.component";
import {
	BUSINESS_TYPE,
	GENDER_TYPE,
	ID_TYPE,
	MARITAL_STATUS,
	SUM_INSURED_TYPE
} from "src/app/share/constants/common.types";
import { CarVariantDialogComponent } from "../car-variant-dialog/car-variant-dialog.component";
import { CommonFunction } from "src/app/share/function/common.function";

declare global {
	interface Window {
		dataLayer: any[];
	}
}

@Component({
	selector: "app-comparison",
	templateUrl: "./comparison.component.html",
	styleUrls: ["./comparison.component.scss"],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false }
		}
	]
})
export class ComparisonComponent {
	partnerCode: string = "carsomeCapital";
	screenWidth: number;

	quotationFrmGroup: FormGroup;
	addOnFrmGroup: FormGroup;
	infoFrmGroup: FormGroup;
	agreementFrmGroup: FormGroup;
	paymentFrmGroup: FormGroup;

	id: string;
	requestData: MotorRequestInterface;
	quotationList: QuotationInterface[] = [];
	quotationId: string;
	selectedQuotation: QuotationInterface;
	quotationToPrint: QuotationInterface;

	@ViewChild("stepper") stepper: MatStepper;

	addOnList: ProductAddOnInterface[] = [];
	includeAddOnList: ProductAddOnInterface[] = [];

	addOnOptionalCategory: string[] = [];

	selectedAddOn: any = [];

	isFloodChecked: boolean;
	isDriverChecked: boolean;

	relationshipList = [
		{ id: "Parent/Parent-in-law", name: "common.parents" },
		{ id: "Spouse", name: "common.spouse" },
		{ id: "Son/Daughter", name: "common.child" },
		{ id: "Sibling/Sibling-in-law/Cousin/Relative", name: "common.sibling" },
		{ id: "Friend/Co-worker", name: "common.friend" }
	];

	hirePurchaseList = [];
	filteredHirePurchase: Observable<string[]>;

	dealerOutletsList: string[] = ["Others"];
	filteredDealerOutletsList: Observable<string[]>;

	promoCode: string;
	promo: PromoCodeInterface;
	promoError: string;

	showRoadTaxList: boolean = false;
	isIncludeDigitalRoadtax: boolean = true;
	includeDigitalRoadTax: boolean;

	stateList: string[] = [
		"Selangor",
		"Kuala Lumpur",
		"Johor",
		"Kedah",
		"Kelantan",
		"Melaka",
		"Negeri Sembilan",
		"Pahang",
		"Perak",
		"Perlis",
		"Pulau Pinang",
		"Sarawak",
		"Sabah",
		"Terengganu"
	];

	filterStateList: string[] = Object.assign([], this.stateList);
	filterDeliveryStateList: string[] = Object.assign([], this.stateList);

	productDocumentInterface: ProductDocumentInterface[] = [];

	selectedPayment: string = "Razer";
	allowEditQuotation: boolean = false;

	isSameAddressCheck: boolean;

	otherInsurersList: string[] = [
		"Allianz",
		"am",
		"greateastern",
		"kurnia",
		"liberty",
		"longpac",
		"Msig",
		"Rhb",
		"tokio"
	];

	uniquePageView: any[] = [
		{
			index: 0,
			pageTitle: "comparison page"
		},
		{
			index: 1,
			pageTitle: "add ons page"
		},
		{
			index: 2,
			pageTitle: "user details page"
		},
		{
			index: 3,
			pageTitle: "order summary page"
		}
	];

	fgapAddOnsIndex: string = "0";

	idTypeOption = ID_TYPE;
	maritalStatusOption = MARITAL_STATUS;
	genderOption = GENDER_TYPE;
	businessTypeOption = BUSINESS_TYPE;

	maxAdditionalDriver: number;
	fgapPriceList: number[];
	windshieldMin: any;
	windshieldMax: any;
	ewpMileagePrice: number = 0;
	ewpMileageMax: number = 0;

	// images
	mykadOrPassport: any[] = [];
	letterOfUndertaking: any[] = [];
	vehiclePhoto: any[] = [];
	ewpFiles = {
		mileageImage: [],
		vehRegCard: [],
		vehInsReport: []
	};

	numberOfLltpSeats: number = 0;

	idTypeList: FieldData[] = [
		{ codeValue: "IC_NO", codeDesc: "IC Number" },
		{ codeValue: "PASSPORT", codeDesc: "Passport" },
		{ codeValue: "POLICE/ARMY", codeDesc: "Police/Army" }
	];
	maxDob: Date = new Date();

	constructor(
		private gtmService: GoogleTagManagerService,
		public translate: TranslateService,
		private alertService: AlertService,
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private ngxSpinnerService: NgxSpinnerService,
		private partnerService: PartnerService,
		private motorDataDataService: MotorDataDataService,
		private transferOwnershipDataService: TransferOwnershipDataService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.getPartnerCode();

		this.initQuotationForm();
		this.initAddOnForm();
		this.initInfoForm();
		this.initAgreementForm();
		this.initPaymentForm();

		this.screenWidth = window.innerWidth;
		this.id = activatedRoute.snapshot.params.id;

		if (this.isUUID(this.id)) {
			this.allowEditQuotation =
				activatedRoute.snapshot.queryParams.editQuotation;
			this.getDetailById();
		} else {
			window.location.href = "/";
		}
		this.translate.onLangChange.subscribe((x) => {
			if (this.selectQuotation) {
				this.getProductDocument();
			}
		});
		this.gtmService.pushTag({
			event: "car_step1"
		});

		// Unique page view tracking for certain affiliate (Comparison page on render)
		if (
			this.partnerService.getPartnerAttribute(
				this.partnerCode,
				"uniquePageViews"
			)
		) {
			window.dataLayer.push({
				event: "virtualPageview",
				pageUrl: window.location.href,
				pageTitle: `${this.partnerCode} comparison page`
			});
		}
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.screenWidth = event.target.innerWidth;
	}

	private getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;
	}

	private initQuotationForm() {
		this.quotationFrmGroup = new FormGroup({
			id: new FormControl(this.quotationId, [Validators.required])
		});
	}

	private initAddOnForm() {
		this.addOnFrmGroup = new FormGroup({
			windshield: new FormControl(),
			drivers: new FormArray([]),
			fgap: new FormControl(),
			ewp: new FormControl(),
			isIncludeRoadtax: new FormControl(),
			isNotBlacklisted: new FormControl(null),
			lltpSeats: new FormControl(this.numberOfLltpSeats[0])
		});

		this.addOnFrmGroup.valueChanges.subscribe(() => {
			this.applyPromoCode();
		});

		this.addOnFrmGroup.controls.isIncludeRoadtax.valueChanges.subscribe((x) => {
			let validators = [];
			let addressValidators = [];
			if (x) {
				validators = [Validators.requiredTrue];
				addressValidators = [Validators.required];
				this.infoFrmGroup.controls.deliveryPostcode.enable();
			} else {
				this.addOnFrmGroup.controls.isNotBlacklisted.reset();
				this.infoFrmGroup.controls.deliveryPostcode.enable();
			}
			this.addOnFrmGroup.controls.isNotBlacklisted.setValidators(validators);
			this.addOnFrmGroup.controls.isNotBlacklisted.updateValueAndValidity();

			this.infoFrmGroup.controls.deliveryAddress.setValidators(
				addressValidators
			);
			this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();

			this.infoFrmGroup.controls.deliveryAddress2.setValidators(
				addressValidators
			);
			this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();

			this.infoFrmGroup.controls.deliveryCity.setValidators(addressValidators);
			this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();

			this.infoFrmGroup.controls.deliveryPostcode.setValidators(
				addressValidators
			);
			this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();

			this.infoFrmGroup.controls.deliveryState.setValidators(addressValidators);
			this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
		});
	}

	private initInfoForm() {
		this.infoFrmGroup = new FormGroup({
			name: new FormControl(null, [Validators.required]),
			occupation: new FormControl(null, []),
			mobileNo: new FormControl(null, [Validators.required]),
			email: new FormControl(null, [Validators.required, Validators.email]),
			address: new FormControl(null, [Validators.required]),
			address2: new FormControl(null, [Validators.required]),
			city: new FormControl(null, [Validators.required]),
			postcode: new FormControl(null, [Validators.required]),
			state: new FormControl(null, [Validators.required]),

			financingType: new FormControl("BankLoan", [Validators.required]),
			bank: new FormControl(""),
			effectiveDate: new FormControl(null, [Validators.required]),
			dealerCase: new FormControl(false, [Validators.required]),
			dealerOutlets: new FormControl(null),
			customDealerOutlet: new FormControl(null),

			deliveryAddress: new FormControl(null),
			deliveryAddress2: new FormControl(null),
			deliveryCity: new FormControl(null),
			deliveryPostcode: new FormControl(null),
			deliveryState: new FormControl(null)
		});
		this.infoFrmGroup.controls.postcode.disable();

		// Delete bank/hirepurchaselist if cash is selected
		this.infoFrmGroup.controls.financingType.valueChanges.subscribe((value) => {
			if (value == "Cash") {
				this.infoFrmGroup.patchValue({
					bank: null
				});
			}
		});

		if (this.partnerCode == "carsomeCapital") {
			// Dealer outlet required if its a dealer case
			this.infoFrmGroup.controls.dealerCase.valueChanges.subscribe((value) => {
				if (value == true) {
					this.infoFrmGroup.controls.dealerOutlets.setValidators([
						Validators.required
					]);
				} else {
					this.infoFrmGroup.controls.dealerOutlets.clearValidators();
					this.infoFrmGroup.controls.customDealerOutlet.clearValidators();
				}
				this.infoFrmGroup.controls.dealerOutlets.updateValueAndValidity();
				this.infoFrmGroup.controls.customDealerOutlet.updateValueAndValidity();
			});

			// Custom dealer case required if Others is picked on dealer outlet
			this.infoFrmGroup.controls.dealerOutlets.valueChanges.subscribe(
				(value) => {
					if (this.infoFrmGroup.value.dealerCase && value == "Others") {
						this.infoFrmGroup.controls.customDealerOutlet.setValidators([
							Validators.required
						]);
					} else {
						this.infoFrmGroup.controls.customDealerOutlet.clearValidators();
					}

					this.infoFrmGroup.controls.customDealerOutlet.updateValueAndValidity();
				}
			);
		}
	}

	private initAgreementForm() {
		this.agreementFrmGroup = new FormGroup({
			agreeInfo: new FormControl(false, [Validators.requiredTrue]),
			doc: new FormArray([])
		});
	}

	private initPaymentForm() {
		this.paymentFrmGroup = new FormGroup({
			payment: new FormControl(false, Validators.requiredTrue)
		});
	}

	private isUUID(uuid: string) {
		return uuid.match(
			"^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
		);
	}

	private getDetailById() {
		this.motorDataDataService.getDetailById(this.id).subscribe({
			next: (x: any) => {
				this.translate.use(x.language);
				this.requestData = x;
				this.getQuotation();
				this.infoFrmGroup.controls.name.setValue(x.name);
				this.infoFrmGroup.controls.email.setValue(x.email);
				this.infoFrmGroup.controls.mobileNo.setValue(x.mobileNo);
				this.infoFrmGroup.controls.postcode.setValue(x.postcode);

				if (x && x.businessType == this.businessTypeOption.transferOwnership) {
					this.infoFrmGroup
						.get("financingType")
						.setValidators(Validators.required);
					this.infoFrmGroup
						.get("effectiveDate")
						.setValidators(Validators.required);
					this.infoFrmGroup
						.get("dealerCase")
						.setValidators(Validators.required);
				} else {
					this.infoFrmGroup.get("financingType").clearValidators();
					this.infoFrmGroup.get("effectiveDate").clearValidators();
					this.infoFrmGroup.get("dealerCase").clearValidators();
				}
				this.infoFrmGroup.get("financingType").updateValueAndValidity();
				this.infoFrmGroup.get("effectiveDate").updateValueAndValidity();
				this.infoFrmGroup.get("dealerCase").updateValueAndValidity();
				this.numberOfLltpSeats = x.seat;
				if (this.numberOfLltpSeats <= 4){
          this.numberOfLltpSeats = 5
        }
				this.addOnFrmGroup.controls.lltpSeats.setValue(this.numberOfLltpSeats);
			},
			error: () => {
				window.location.href = "/";
			}
		});
	}

	private getQuotation() {
		this.motorDataDataService.getQuotationById(this.id).subscribe({
			next: (x) => {
				this.quotationList = x;
				if (this.activatedRoute.snapshot.queryParams.id) {
					setTimeout(() => {
						this.selectQuotation(this.activatedRoute.snapshot.queryParams.id);
					}, 100);
				}
				if (this.allowEditQuotation) {
					this.editQuotation();
					this.allowEditQuotation = false;
				}
			},
			error: () => {
				window.location.href = "/";
			}
		});
	}

	private getAddOn() {
		this.includeAddOnList = [];
		this.motorDataDataService.getAddOn(this.id, this.quotationId).subscribe({
			next: (x) => {
				this.addOnList = x;
				if (this.addOnList) {
					this.sortDriversAddonSequence();
				}
				this.addOnOptionalCategory = [
					...new Set(
						this.addOnList.filter((y) => !y.included).map((z) => z.category)
					)
				];
				this.addOnList.forEach((y: any) => {
					if (y.category == "Windshield") {
						this.windshieldMin = this.getMinMaxWindscreenPrice(y, "min");
						this.windshieldMax = this.getMinMaxWindscreenPrice(y, "max");

						this.addOnFrmGroup.controls.windshield.setValue(
							this.windshieldMin || 500
						);
					}

					if (y.code == "AdditionalDrivers") {
						this.maxAdditionalDriver = this.getRule(y)?.max ?? null;
					}

					if (y.code == "FGAP") {
						this.fgapPriceList = eval(y.evaluate);
						if (this.fgapPriceList)
							this.addOnFrmGroup.controls.fgap.setValue(this.fgapPriceList[0]);
					}

					if (y.code == "EWP") {
						let conditionList = eval(y.condition);
						this.ewpMileageMax = conditionList[conditionList.length - 1].Max;

						this.selectedAddOn.push(y);
						this.addOnFrmGroup.controls.ewp.setValidators([
							Validators.max(this.ewpMileageMax),
							Validators.required
						]);
					}
					this.addOnFrmGroup.controls.ewp.updateValueAndValidity();

					if (y.included) {
						this.includeAddOnList.push(y);
						this.selectedAddOn.push(y);
					}
				});
			},
			error: () => {
				window.location.href = "/";
			}
		});
	}

	sortDriversAddonSequence() {
		// Define the custom order for specific codes
		const order = ["UnlimitedDrivers", "AdditionalDrivers"];

		this.addOnList.sort((a, b) => {
			// Handle custom sorting logic
			const indexA = order.indexOf(a.code);
			const indexB = order.indexOf(b.code);

			if (indexA > -1 && indexB > -1) {
				return indexA - indexB; // Both are in the order list
			} else if (indexA > -1) {
				return -1; // a should come before b
			} else if (indexB > -1) {
				return 1; // b should come before a
			} else {
				return 0; // Default sorting for other items
			}
		});
	}

	fgapAddOnsChanged(index: string) {
		this.fgapAddOnsIndex = index;
	}

	step2DisableBtnCondition() {
		// IF EWP Add On Selected, this is the btn disable condition
		if (this.selectedAddOn.find((e) => e.category == "EWP")) {
			if (
				this.addOnFrmGroup.valid &&
				this.ewpFiles.mileageImage.length &&
				this.ewpFiles.vehRegCard.length &&
				this.ewpFiles.vehInsReport.length
			) {
				return false;
			}
		}
		// If no add on selected, this is the btn disable condition
		else if (this.addOnFrmGroup.valid) {
			return false;
		}
		return true;
	}

	private getProductDocument() {
		if (!this.selectedQuotation) return;
		this.productDocumentInterface = [];
		let frmArray = this.getAgreeFormArray();
		frmArray.clear();
		this.motorDataDataService
			.getProductDocumentById(
				this.selectedQuotation.productId,
				this.translate.currentLang,
				this.requestData.insuranceType
			)
			.subscribe({
				next: (x) => {
					this.productDocumentInterface = x;
					x.forEach((doc) => {
						if (doc.neededUserAgree) {
							let frm = this.formBuilder.group({
								checked: new FormControl(false, [Validators.requiredTrue]),
								name: new FormControl(doc.name),
								url: new FormControl(doc.url)
							});
							frmArray.push(frm);
						}
					});
				}
			});
	}

	private promoCheck() {
		if (!this.promo) return;
		if (
			this.promo.customCode == "RoadTaxDelivery" &&
			!this.addOnFrmGroup.value.isIncludeRoadtax
		) {
			this.promo = null;
			this.promoError = "InvalidPromoCode";
		}
	}

	onStepChange(e: StepperSelectionEvent) {
		this.gtmService.pushTag({
			event: "car_step" + (e.selectedIndex + 1)
		});

		this.paymentFrmGroup.controls.payment.setValue(e.selectedIndex == 4);
		if (e.selectedIndex == 3) {
			let value = this.infoFrmGroup.getRawValue();
			if (
				this.addOnFrmGroup.value?.isIncludeRoadtax &&
				this.isSameAddressCheck
			) {
				this.infoFrmGroup.controls.deliveryAddress.setValue(value.address);
				this.infoFrmGroup.controls.deliveryAddress2.setValue(value.address2);
				this.infoFrmGroup.controls.deliveryCity.setValue(value.city);
				this.infoFrmGroup.controls.deliveryPostcode.setValue(value.postcode);
				this.infoFrmGroup.controls.deliveryState.setValue(value.state);
			} else if (!this.addOnFrmGroup.value?.isIncludeRoadtax) {
				this.infoFrmGroup.controls.deliveryAddress.reset();
				this.infoFrmGroup.controls.deliveryAddress2.reset();
				this.infoFrmGroup.controls.deliveryCity.reset();
				this.infoFrmGroup.controls.deliveryPostcode.reset();
				this.infoFrmGroup.controls.deliveryState.reset();

				this.infoFrmGroup.controls.deliveryAddress.setValidators([]);
				this.infoFrmGroup.controls.deliveryAddress2.setValidators([]);
				this.infoFrmGroup.controls.deliveryCity.setValidators([]);
				this.infoFrmGroup.controls.deliveryPostcode.setValidators([]);
				this.infoFrmGroup.controls.deliveryState.setValidators([]);

				this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();
				this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();
				this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();
				this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();
				this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
			}
		}
		if (e.selectedIndex == 1 || e.selectedIndex == 2) {
			this.applyPromoCode();
		}

		if (e.selectedIndex == 2) this.getListDropdowns();

		// Unique page view tracking for certain affiliate
		if (
			this.partnerService.getPartnerAttribute(
				this.partnerCode,
				"uniquePageViews"
			)
		) {
			this.uniquePageView.forEach((x) => {
				if (e.selectedIndex == x.index) {
					window.dataLayer.push({
						event: "virtualPageview",
						pageUrl: window.location.href,
						pageTitle: `${this.partnerCode} ${x.pageTitle}`
					});
				}
			});
		}
	}

	applyPromoCode() {
		if (!this.promoCode) return;
		this.ngxSpinnerService.show();
		this.promoError = null;
		this.promo = null;
		this.motorDataDataService
			.applyPromoCode(this.id, this.promoCode, this.getGrossPremium())
			.subscribe({
				next: (x) => {
					this.promo = x;
					this.ngxSpinnerService.hide();
					this.promoCheck();
				},
				error: (err) => {
					this.ngxSpinnerService.hide();
					this.promoError = err.error.message;
				}
			});
	}

	clearPromoCode() {
		this.promoCode = null;
		this.promoError = null;
		this.promo = null;
	}

	getFormArray() {
		return this.addOnFrmGroup.get("drivers") as FormArray;
	}

	getAgreeFormArray() {
		return this.agreementFrmGroup.get("doc") as FormArray;
	}

	addDriver() {
		let frmArray = this.getFormArray();
		let form = this.formBuilder.group({
			name: [null, Validators.required],
			idType: [this.idTypeList[0].codeValue, Validators.required],
			identityNo: [
				null,
				[Validators.required, ValidatorFunction.nricValidator()]
			],
			dob: [null, Validators.required],
			gender: [this.genderOption.male, Validators.required],
			maritalStatus: [this.maritalStatusOption.single, Validators.required],
			relationship: [null, Validators.required],
			isMalaysian: [true]
		});

		form.get("idType").valueChanges.subscribe((value) => {
			if (value === "IC_NO") {
				form
					.get("identityNo")
					.setValidators([
						Validators.required,
						ValidatorFunction.nricValidator()
					]);
			} else {
				form.get("identityNo").setValidators(Validators.required);
			}

			if (["IC_NO", "POLICE/ARMY"].includes(value)) {
				form.get("isMalaysian").patchValue(true);
			} else {
				form.get("isMalaysian").patchValue(false);
			}

			form.get("identityNo").reset();
			form.get("identityNo").updateValueAndValidity();
		});

		form.get("identityNo").valueChanges.subscribe((value) => {
			if (
				form.get("identityNo").invalid &&
				form.get("idType").value === "IC_NO"
			) {
				form.get("dob").reset();
			}

			if (
				form.get("identityNo").valid &&
				form.get("idType").value === "IC_NO"
			) {
				let date = moment(value.substring(0, 6), "YYMMDD");
				let lastChar = value.substring(11);
				let gender = lastChar % 2 ? "M" : "F";

				if (moment().isBefore(date)) {
					date.subtract(100, "year");
				}
				form.patchValue({
					dob: date.toDate(),
					gender: gender
				});
			}
		});

		frmArray.push(form);
	}

	deleteDriver(i: number) {
		let frmArray = this.getFormArray();
		frmArray.removeAt(i);
	}

	selectQuotation(id: string) {
		let lastQuotationId = this.quotationId;
		this.quotationFrmGroup.controls.id.setValue(id);
		this.quotationId = id;
		this.selectedQuotation = this.quotationList.find((x) => x.id == id);

		this.router.navigate([], {
			queryParams: {
				id: id,
				partnerCode: this.partnerCode
			}
		});
		if (this.addOnList.length == 0) {
			this.getAddOn();
		} else if (lastQuotationId != this.quotationId) {
			this.addOnList = [];
			this.initAddOnForm();
			this.getAddOn();
			this.selectedAddOn = [];
			this.isDriverChecked = false;
			this.isFloodChecked = false;
		}
		this.getProductDocument();
		this.stepper.next();
	}

	editQuotation() {
		let dialogRef = this.dialog.open(EditQuotationsComponent, {
			data: {
				id: this.id,
				request: this.requestData,
				quotationList: this.quotationList
			},
			panelClass: "edit-quote-dialog"
		});

		dialogRef.afterClosed().subscribe((x) => {
			if (x) {
				let successDialog = this.dialog.open(SubmitSuccessDialogComponent, {
					data: this.requestData.email,
					panelClass: "success-dialog"
				});

				successDialog.afterClosed().subscribe(() => {
					window.location.reload();
				});
			}
		});
	}

	carDetails() {
		let dialogRef = this.dialog.open(CarDetailsDialogComponent, {
			data: this.requestData
		});

		dialogRef.afterClosed().subscribe((x) => {});
	}

	carPlan() {
		let dialogRef = this.dialog.open(CarPlanDialogComponent, {
			data: this.requestData
		});

		dialogRef.afterClosed().subscribe((x) => {});
	}

	getAddOnListByCategory(val: string) {
		return this.addOnList.filter((x) => x.category == val);
	}

	isAddOnSelected(item: ProductAddOnInterface) {
		return this.selectedAddOn.some((x) => x.id == item.id);
	}

	getPrice(item: ProductAddOnInterface, val?: any) {
		try {
			let evaluate = item.evaluate;
			let condition = item.condition;

			if (item.code == "FGAP") {
				const priceList = eval(evaluate);
				const valNum = parseInt(val);
				return priceList[valNum];
			}

			if (item.code == "EWP") {
				let conditionList = eval(condition);

				const ewpObject = conditionList.filter((x) => val <= x.Max);
				this.ewpMileagePrice = ewpObject[0].Value || 0;
				return ewpObject[0].Value;
			}

			if (val) {
				evaluate = evaluate.replace("{val}", val);

				if (condition) {
					condition = condition.replace("{val}", val);
					if (item.isConditionFirst) {
						let status = Boolean(eval(condition));
						if (status) {
							return eval(evaluate);
						} else {
							return 0;
						}
					}
				}
			}

			return eval(evaluate);
		} catch {
			return 0;
		}
	}

	toggleAddOn(e: MatCheckboxChange) {
		let value: ProductAddOnInterface = e.source.value as any;

		if (e.checked) {
			this.selectedAddOn.push(value);
		} else {
			let index = this.selectedAddOn.findIndex((x) => x.id == value.id);
			if (index >= 0) {
				this.selectedAddOn.splice(index, 1);
			}
		}
		if (value.category == "Drivers") {
			let frmArray = this.getFormArray();
			frmArray.reset();
			frmArray.clear();
			if (e.checked && value.code == "AdditionalDrivers") this.addDriver();
		}
		if (value.category == "EWP") {
			if (e.checked) {
				this.addOnFrmGroup.controls.ewp.setValidators([
					Validators.max(this.ewpMileageMax),
					Validators.required
				]);
			} else {
				this.addOnFrmGroup.controls.ewp.clearValidators();
			}
			this.addOnFrmGroup.controls.ewp.updateValueAndValidity();
		}
		if (value.category == "Windshield") {
			if (e.checked) {
				this.addOnFrmGroup.controls.windshield.setValidators([
					Validators.min(this.windshieldMin),
					Validators.max(this.windshieldMax),
					Validators.required
				]);
			} else {
				this.addOnFrmGroup.controls.windshield.clearValidators();
				this.addOnFrmGroup.controls.windshield.setValue(
					this.getMinMaxWindscreenPrice(value, "min")
				);
			}
			this.addOnFrmGroup.controls.windshield.updateValueAndValidity();
		}
	}

	getListDropdowns() {
		this.transferOwnershipDataService.getHirePurchaseList().subscribe((x) => {
			this.hirePurchaseList = x;
			this.filteredHirePurchase =
				this.infoFrmGroup.controls.bank.valueChanges.pipe(
					startWith(""),
					map((value) =>
						this.filterAutocompleteList(value || "", this.hirePurchaseList)
					)
				);
		});

		this.filteredDealerOutletsList =
			this.infoFrmGroup.controls.dealerOutlets.valueChanges.pipe(
				startWith(""),
				map((value) =>
					this.filterAutocompleteList(value || "", this.dealerOutletsList)
				)
			);
	}

	paymentTypeChange(paymentType: string) {
		this.selectedPayment = paymentType;
	}

	radioChange(e: ProductAddOnInterface, isCheckbox: boolean = false) {
		let index = this.selectedAddOn.findIndex((x) => x.category == e.category);
		if (index >= 0) this.selectedAddOn.splice(index, 1);
		if (!isCheckbox || index == -1) {
			this.selectedAddOn.push(e);
		}

		let frmArray = this.getFormArray();
		if (frmArray.length == 0 && e.code == "AdditionalDrivers") this.addDriver();
		else {
			frmArray.reset();
			frmArray.clear();
		}
	}

	getRule(e: ProductAddOnInterface) {
		if (e.rules) {
			return JSON.parse(e.rules);
		}
		return null;
	}

	hasSelectedCode(val: string) {
		return this.selectedAddOn.some((x) => x.code == val);
	}

	hasSelectedCategory(val: string) {
		return this.selectedAddOn.some((x) => x.category == val);
	}

	// -- Not used anymore at #freeDriverText
	// getConditionMax(e: ProductAddOnInterface) {
	//   if (e.code != 'AdditionalDrivers' || !e.condition) return 0;
	//   let count = 1;
	//   for (let index = 1; index < 9999; index++) {
	//     let condition = e.condition.replace('{val}', index.toString());
	//     let status = Boolean(eval(condition));
	//     if (status) {
	//       count = index - 1;
	//       break;
	//     }
	//   }
	//   return count;
	// }

	getGrossPremium() {
		if (!this.requestData && !this.selectedQuotation) return 0;

		return this.selectedQuotation?.totalBasePremium + this.getTotalAddOnPrice();
	}

	getTotalAddOnPrice() {
		let total = 0;

		this.selectedAddOn.forEach((x) => {
			if (x.code == "AdditionalDrivers")
				total += this.getPrice(x, this.getFormArray().length);
			else if (x.category == "Windshield")
				total += this.getPrice(x, this.addOnFrmGroup.value.windshield);
			else if (x.category == "FGAP") {
				if (this.addOnFrmGroup.value.fgap) {
					total += this.addOnFrmGroup.value.fgap;
				} else {
					total += this.getPrice(x, "0");
				}
			} else if (x.category == "EWP") {
				total += this.getPrice(x, this.addOnFrmGroup.value.ewp);
			} else if (x.category == "LLTP") {
				total += this.getPrice(x, this.numberOfLltpSeats);
			} else total += this.getPrice(x);
		});
		return total;
	}

	getTax() {
		if (!this.selectedQuotation) return 0;
		let total = this.getGrossPremium();

		return total * this.selectedQuotation.taxRate;
	}

	getTotalNetPremium() {
		var total =
			Number(this.getGrossPremium().toFixed(2)) +
			Number(this.getTax().toFixed(2)) +
			this.selectedQuotation?.stampDutyPrice;
		total =
			this.selectedQuotation &&
			this.selectedQuotation.code.toLowerCase() === "takaful_malaysia"
				? CommonFunction.customSTMBRound(total)
				: total;

		return total;
	}

	getPlatformDiscountRate() {
		return (
			this.selectedQuotation?.platformDiscountRate * this.getGrossPremium()
		);
	}

	getTotalPromo() {
		if (!this.promo) return 0;

		let evaluate = this.promo.evaluate;
		evaluate = evaluate.replace(
			"{TotalGrossPremium}",
			this.getGrossPremium().toString()
		);
		return Number.parseFloat(eval(evaluate));
	}

	getTotalCharge() {
		let total = this.getTotalNetPremium() - this.getPlatformDiscountRate();
		if (this.addOnFrmGroup.value?.isIncludeRoadtax) {
			total =
				total +
				this.requestData.roadTaxPrice +
				this.requestData.roadTaxDeliveryPrice;
		}
		return total;
	}

	getServiceFee() {
		if (!this.selectedQuotation) return 0;
		return (
			this.selectedQuotation.serviceTaxRate *
			(this.getTotalCharge() - this.getTotalPromo())
		);
	}

	getTotal() {
		return this.getTotalCharge() + this.getServiceFee() - this.getTotalPromo();
	}

	getIdentityNoLabel() {
		if (this.requestData?.isMalaysian && !this.requestData?.isCompany)
			return "common.icNo";
		else if (!this.requestData?.isMalaysian) return "common.passport";
		return "common.businessRegistrationNo";
	}

	getNameLabel() {
		if (this.requestData?.isMalaysian && !this.requestData?.isCompany)
			return "common.namePerIc";
		else if (!this.requestData?.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

	isAddressValid() {
		return !(
			this.infoFrmGroup.controls.address.invalid ||
			this.infoFrmGroup.controls.address2.invalid ||
			this.infoFrmGroup.controls.city.invalid ||
			this.infoFrmGroup.controls.state.invalid
		);
	}

	isSameAddressChecked() {
		if (!this.isAddressValid()) {
			return false;
		}
		let value = this.infoFrmGroup.getRawValue();
		let addr = `${value.address || ""}${value.address2 || ""}${
			value.city || ""
		}${value.postcode || ""}${value.state || ""}`;
		let deliveryAddr = `${value.deliveryAddress || ""}${
			value.deliveryAddress2 || ""
		}${value.deliveryCity || ""}${value.deliveryPostcode || ""}${
			value.deliveryState || ""
		}`;
		return addr == deliveryAddr;
	}

	addressIsChecked(e: MatCheckboxChange) {
		this.isSameAddressCheck = e.checked;
		let validatorList = e.checked ? [] : [Validators.required];
		if (!e.checked) {
			this.infoFrmGroup.controls.deliveryAddress.reset();
			this.infoFrmGroup.controls.deliveryAddress2.reset();
			this.infoFrmGroup.controls.deliveryCity.reset();
			this.infoFrmGroup.controls.deliveryPostcode.reset();
			this.infoFrmGroup.controls.deliveryState.reset();
		}

		this.infoFrmGroup.controls.deliveryAddress.setValidators(validatorList);
		this.infoFrmGroup.controls.deliveryAddress2.setValidators(validatorList);
		this.infoFrmGroup.controls.deliveryCity.setValidators(validatorList);
		this.infoFrmGroup.controls.deliveryPostcode.setValidators(validatorList);
		this.infoFrmGroup.controls.deliveryState.setValidators(validatorList);

		this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();
		this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();
		this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();
		this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();
		this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
	}

	submit() {
		if (this.agreementFrmGroup.invalid) return;

		let dialogRef = this.dialog.open(AgentCodeDialogComponent, {
			panelClass: "agent-code-dialog",
			data: {
				id: this.id,
				quotationId: this.quotationId,
				selectedAddOn: this.selectedAddOn,
				addOnValue: this.addOnFrmGroup.value,
				infoValue: this.infoFrmGroup.value,
				mykadOrPassport: this.mykadOrPassport,
				letterOfUndertaking: this.letterOfUndertaking,
				fgapAddOnsIndex: this.fgapAddOnsIndex,
				includeRoadTax: this.addOnFrmGroup.value?.isIncludeRoadtax,
				vehiclePhoto: this.vehiclePhoto,
				ewpAmount: this.ewpMileagePrice,
				ewpFiles: this.ewpFiles,
				promoCodeId:
					this.promo?.id !== null && typeof (this.promo?.id !== undefined)
						? this.promo?.id
						: null
			}
		});

		dialogRef.afterClosed().subscribe((x) => {
			if (x) {
				this.router.navigate(["/"], {
					queryParams: {
						partnerCode: this.partnerCode
					}
				});
			}
		});
	}

	getFormArrayControlError(i, formControlName) {
		return (this.getFormArray().controls[i] as FormGroup).controls[
			formControlName
		].errors;
	}

	benefitDialog(item) {
		this.dialog.open(BenefitDialogComponent, {
			panelClass: "benefit-dialog",
			data: {
				productId: item.productId,
				code: item.code,
				lang: this.translate.currentLang,
				insuranceType: this.requestData.insuranceType
			}
		});
	}

	getAddOnCode(code) {
		return `addOn.${code}`;
	}

	hasAddOn() {
		return this.selectedAddOn.filter((x) => !x.hidePriceBreakdown).length != 0;
	}

	addonLabel(value: number) {
		return "RM " + value.toFixed(2).toLocaleString();
	}

	getPrintedQuotation(id: string) {
		this.selectedQuotation = this.quotationList.find((x) => x.id == id);
	}

	printComparison(id: string) {
		this.getPrintedQuotation(id);

		this.print();
	}

	print() {
		const printContent = document.getElementById("printOrderSummary");
		const iframe = document?.getElementById("iframePrint") as HTMLIFrameElement;
		var pri = iframe.contentWindow;

		pri.document.open();
		pri.document.write(printContent.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	handleFileInput(e, name = null) {
		const files = e.target.files;
		if (files.length) {
			for (let i = 0; i < files.length; i++) {
				let file: File = e.target.files[i];
				if (!FileFunction.isImageOrPdf(file)) {
					this.alertService.openSnackBar(
						`Fail to upload ${file.name} - just accept image type (jpeg, png & svg)`
					);
					return;
				}
				if (!name) {
					this[e.target.name].push(files[i]);
				} else {
					this[e.target.name][name].push(files[i]);
				}
			}
		}

		e.target.value = "";
	}

	deleteFile(file: any, index: number, name = null) {
		if (!name) {
			this[file].splice(index, 1);
		} else {
			this[file][name].splice(index, 1);
		}
	}

	dealerCaseChanged(e: any) {
		if (e.value) {
			let dialogRef = this.dialog.open(DealerCaseDialogComponent, {
				panelClass: "success-dialog",
				disableClose: true,
				autoFocus: false
			});

			dialogRef.afterClosed().subscribe((x) => {
				if (x) {
					this.infoFrmGroup.patchValue({
						dealerCase: false
					});
				}
			});
		}
	}

	filterAutocompleteList(value: string, list: string[]) {
		if (value) {
			const filterValue = value.toLowerCase();

			return list.filter((x) => x.toLowerCase().includes(filterValue));
		}
		return list;
	}

	getUserDetailsBtnCondition(): boolean {
		if (
			this.requestData.businessType == this.businessTypeOption.transferOwnership
		) {
			if (
				this.infoFrmGroup.invalid ||
				!this.mykadOrPassport.length ||
				!this.letterOfUndertaking.length ||
				!this.vehiclePhoto.length
			) {
				return true;
			} else {
				return false;
			}
		} else if (
			this.requestData.businessType == this.businessTypeOption.renewal
		) {
			if (this.infoFrmGroup.invalid) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	openCarVariantDialog(id: string) {
		let dialogRef = this.dialog.open(CarVariantDialogComponent, {
			panelClass: "edit-quote-dialog",
			data: { requestData: this.requestData, linkId: this.id },
			autoFocus: false
		});

		dialogRef.afterClosed().subscribe((result: any) => {
			if (result && result.data === true) {
				this.selectQuotation(id);
			}
		});
	}

	getMinMaxWindscreenPrice(
		addOn: ProductAddOnInterface,
		key: "min" | "max"
	): number {
		const rule = this.getRule(addOn);
		const { maxWindshieldPrice, minWindshieldPrice } = this.selectedQuotation;

		const config = {
			max: maxWindshieldPrice || rule?.max || 10000,
			min: minWindshieldPrice || rule?.min || 500
		};

		return config[key];
	}
}

interface FieldData {
	codeValue: string;
	codeDesc: string;
}
